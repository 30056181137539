import { Avatar, Box, Button, Container, Heading, Stack, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import introVideo from '../../assets/videos/Intro.mp4'
import MetaData from '../Layout/MetaData/MetaData'

const Founder = ()=> (
    <Stack direction={['column', 'row']} spacing={['4', '16']} padding="8">
        <VStack>
            <Avatar boxSize={['40', '48']} src="https://res.cloudinary.com/devwebcloud/image/upload/v1690130795/SubscriptionProject/UserAvatar/snrk2gtxlsometdkmk0q.png" />
            <Text opacity={'0.7'} children={'Co-Founder'} />
        </VStack>
        <VStack justifyContent={'center'} alignItems={["center", "flex-start"]}>
            <Heading children={'Khushi Purwar'} size={["md", "xl"]} />
            <Text children={"Hi, I am a full-stack developer and a teacher. Our mission is to provide quality content at reasonable price."} />
        </VStack>
    </Stack>
)

const VideoPlayer = ()=>(
    <Box px="10">
        <video 
          autoPlay 
          loop
          muted
          controls 
          controlsList='nodownload nofullscreen noremoteplayback' 
          disablePictureInPicture
          disableRemotePlayback
          src={introVideo}>
          
        </video>
    </Box>
)

const About = () => {
  return (
    <>
    <MetaData title="About Us" />
    <Container maxW={"container.lg"} padding={'16'} boxShadow={'lg'}>
        <Heading children={'About Us'} textAlign={['center', 'left']} />
        <Founder />
        <Stack m={'8'} direction={["column", "row"]} alignItems={'center'}>
            <Text fontFamily={'cursive'} m="8" textAlign={["center", "left"]}>
                We are a video streaming platform with some premium courses available only for premium users.
            </Text>
            <Link to="/subscribe" >
                <Button variant={'ghost'} colorScheme='yellow'>Checkout our Plan</Button>
            </Link>
        </Stack>

        <VideoPlayer />
    
    </Container>
    </>
  )
}

export default About