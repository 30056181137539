import React, {useEffect, useState} from 'react'
import {Box, Button, Grid, HStack, Heading, Image,  Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import cursor from '../../../assets/images/cursor.png'
import Sidebar from '../Sidebar'
import {RiDeleteBin7Fill} from 'react-icons/ri';
import CourseModal  from './CourseModal';
import { useSelector, useDispatch } from 'react-redux';
import { getAllCourses, getCourseLectures } from '../../../redux/actions/courseAction';
import Loader from '../../Layout/Loader/Loader'
import { addCourseLectures, deleteCourse, deleteCourseLecture } from '../../../redux/actions/adminAction';
import {toast} from 'react-hot-toast';
import MetaData from '../../Layout/MetaData/MetaData';

const AdminCourses = () => {

  const {courses, loading: courseLoading, lectures} = useSelector(state => state.course)
  const {loading, error, message} = useSelector(state => state.admin)
  const dispatch = useDispatch();
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [courseId, setCourseId] = useState("");
  const [courseTitle, setCourseTitle] = useState("");

  const courseDetailsHandler = (courseId, courseTitle)=>{
    dispatch(getCourseLectures(courseId));
    onOpen();
    setCourseId(courseId);
    setCourseTitle(courseTitle);
  }

  const deleteCourseHandler = (courseId)=>{
    dispatch(deleteCourse(courseId));
  }

  const deleteLectureHandler = async(courseId, lectureId)=>{
    await dispatch(deleteCourseLecture(courseId, lectureId));

    dispatch(getCourseLectures(courseId));
  }

  const addLectureHandler = async(e, courseId, title, description, video)=>{
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title)
    formData.append("description", description)
    formData.append("file", video)

    await dispatch(addCourseLectures(courseId, formData))

    dispatch(getCourseLectures(courseId));
  }

  useEffect(() => {
    if(error){
      toast.error(error);
      dispatch({
        type: "CLEAR_ERROR"
      })
    }

    if(message){
      toast.success(message);
      dispatch({
        type: "CLEAR_MESSAGE"
      })
    }

    dispatch(getAllCourses());
  }, [dispatch, message, error]);
  
  return (
    <>
      <MetaData title="Admin Courses" />
      <Grid templateColumns={["1fr", "5fr 1fr"]} minH={"100vh"} css={{cursor:`url(${cursor}), default`}}>
          {
            courseLoading ? 
            <Loader color='purple.500' />: 
            (
              <Box p={["0", "12"]} overflowX={"auto"}>
              <Heading children="All Courses" textTransform={'uppercase'} my={'10'} textAlign={["center", "left"]} />
              <TableContainer w={["100vw", "full"]}>
                <Table variant={"simple"} size={"lg"}>
                  <TableCaption opacity={"0.7"} >All Available Courses present in the database.</TableCaption>
                  <Thead>
                    <Tr>
                      <Th>Id</Th>
                      <Th>Poster</Th>
                      <Th>Title</Th>
                      <Th>Category</Th>
                      <Th>Creator</Th>
                      <Th isNumeric>Views</Th>
                      <Th isNumeric>Lectures</Th>
                      <Th isNumeric>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {courses.map(item => (
                      <Row  
                        key={item._id} 
                        item={item} 
                        courseDetailsHandler={courseDetailsHandler} 
                        deleteCourseHandler={deleteCourseHandler} 
                        
                      />
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>

              <CourseModal 
                isOpen={isOpen} 
                onClose={onClose} 
                id={courseId} 
                courseTitle={courseTitle}
                deleteLectureHandler={deleteLectureHandler} 
                addLectureHandler={addLectureHandler} 
                lectures={lectures}
                loading={loading}
              />
            </Box>
            )
          }
          <Sidebar />
      </Grid>
    </>
  )
}

export default AdminCourses

function Row ({item, courseDetailsHandler, deleteCourseHandler}){
  return (
    <Tr>
      <Td>#{item._id}</Td>
      <Td>
        <Image src={item.poster.url} />
      </Td>
      <Td>{item.title}</Td>
      <Td textTransform={"uppercase"}>{item.category}</Td>
      <Td>{item.createdBy}</Td>
      <Td isNumeric>{item.views}</Td>
      <Td isNumeric>{item.numOfVideos}</Td>
      <Td isNumeric>
        <HStack justifyContent={"flex-end"}>
          <Button variant={"outline"} color={"purple.500"} onClick={() => courseDetailsHandler(item._id, item.title)}>View Lectures</Button>
          <Button color={"purple.600"} onClick={() => deleteCourseHandler(item._id)}>
            <RiDeleteBin7Fill />
          </Button>
        </HStack>
      </Td>
    </Tr>
  )
}