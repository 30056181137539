import { Box, Button, Container, FormLabel, Heading, Input, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from '../../redux/actions/userAction';
import MetaData from '../Layout/MetaData/MetaData';

const ResetPassword = () => {
    const [password, setPassword] = useState("");
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {loading} = useSelector(state=>state.user)

    const submitHandler = (e)=>{
        e.preventDefault();
        dispatch(resetPassword( params.token , password));
        navigate("/login");
    }

    return (
      <>
       <MetaData title="Reset Password" />
        <Container py={'16'} h={'90vh'}>
          <VStack h={"full"} justifyContent={"center"} spacing={'16'}>
            <Heading children={"Reset Password"} textTransform={'uppercase'} />
            <form style={{width:'100%'}} onSubmit={submitHandler}>
                <Box marginY={"4"}>
                    <FormLabel htmlFor='password' children="New Password" />
                    <Input 
                        required 
                        id='password' 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder='************'
                        type='password'
                        focusBorderColor='yellow.500' 
                    />
                </Box>
                <Button mt={"10"} isLoading={loading} type='submit' w={'full'} colorScheme='yellow'>Reset Password</Button>
            </form>
            
        </VStack>
      </Container>
      </>
    )
}

export default ResetPassword