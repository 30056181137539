import React , {useEffect} from 'react'
import {Box, Button, Grid, HStack, Heading, Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import cursor from '../../../assets/images/cursor.png'
import Sidebar from '../Sidebar'
import {RiDeleteBin7Fill} from 'react-icons/ri';
import {useDispatch, useSelector} from 'react-redux'
import { deleteUser, getAllUsers, updateUserRole } from '../../../redux/actions/adminAction';
import Loader from '../../Layout/Loader/Loader';
import {toast} from 'react-hot-toast';
import MetaData from '../../Layout/MetaData/MetaData';

const Users = () => {
  const dispatch = useDispatch();
  const {loading, users, error, message} = useSelector(state => state.admin)

  const updateRoleHandler = async(userId)=>{
    await dispatch(updateUserRole(userId))
  }

  const deleteUserHandler = async (userId)=>{
    await dispatch(deleteUser(userId))
  }

  useEffect(() => {

    if(error){
      toast.error(error);
      dispatch({
        type: "CLEAR_ERROR"
      })
    }

    if(message){
      toast.success(message);
      dispatch({
        type: "CLEAR_MESSAGE"
      })
    }

    dispatch(getAllUsers());
  }, [dispatch, error, message]);
  

  return (
    <>
      <MetaData title="Admin Users" />
      <Grid templateColumns={["1fr", "5fr 1fr"]} minH={"100vh"} css={{cursor:`url(${cursor}), default`}}>
          {loading ? 
          <Loader color='purple.500' /> :
          (
            <Box p={["0", "12"]} overflowX={"auto"}>
              <Heading children="All Users" textTransform={'uppercase'} my={'10'} textAlign={["center", "left"]} />
              <TableContainer w={["100vw", "full"]}>
                <Table variant={"simple"} size={"lg"}>
                  <TableCaption opacity={"0.7"} >All Available Users present in the database.</TableCaption>
                  <Thead>
                    <Tr>
                      <Th>Id</Th>
                      <Th>Name</Th>
                      <Th>Email</Th>
                      <Th>Role</Th>
                      <Th>Subscription</Th>
                      <Th isNumeric>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {users && users.map(item => (
                      <Row  key={item._id} item={item} updateRoleHandler={updateRoleHandler} deleteUserHandler={deleteUserHandler} />
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          )}
          <Sidebar />
      </Grid>
    </>
  )
}

export default Users

function Row ({item, updateRoleHandler, deleteUserHandler}){
  return (
    <Tr>
      <Td>#{item._id}</Td>
      <Td>{item.name}</Td>
      <Td>{item.email}</Td>
      <Td>{item.role}</Td>
      <Td>{item.subscription && item.subscription.status === "active" ? "Active" : "Not Active"}</Td>
      <Td isNumeric>
        <HStack justifyContent={"flex-end"}>
          <Button variant={"outline"} color={"purple.500"} onClick={() => updateRoleHandler(item._id)}>Change Role</Button>
          <Button color={"purple.600"} onClick={() => deleteUserHandler(item._id)}>
            <RiDeleteBin7Fill />
          </Button>
        </HStack>
      </Td>
    </Tr>
  )
}
