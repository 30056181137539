import { server } from "../store";
import axios from 'axios';

export const createCourse = (formData)=> async(dispatch)=>{
    try {
        dispatch({
            type: "CREATE_COURSE_REQUEST"
        })

        const {data} = await axios.post(`${server}/course`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        });

        dispatch({
            type: "CREATE_COURSE_SUCCESS",
            payload: data.message
        })
    } catch (error) {
        dispatch({
            type: 'CREATE_COURSE_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const deleteCourse = (id)=> async(dispatch)=>{
    try {
        dispatch({
            type: "DELETE_COURSE_REQUEST"
        })

        const {data} = await axios.delete(`${server}/course/${id}`, {
            withCredentials: true
        });

        dispatch({
            type: "DELETE_COURSE_SUCCESS",
            payload: data.message
        })
    } catch (error) {
        dispatch({
            type: 'DELETE_COURSE_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const addCourseLectures = (id, formData)=> async(dispatch)=>{
    try {
        dispatch({
            type: "ADD_COURSE_LECTURES_REQUEST"
        })

        const {data} = await axios.post(`${server}/course/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        });

        dispatch({
            type: "ADD_COURSE_LECTURES_SUCCESS",
            payload: data.message
        })
    } catch (error) {
        dispatch({
            type: 'ADD_COURSE_LECTURES_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const deleteCourseLecture = (courseId, lectureId)=> async(dispatch)=>{
    try {
        dispatch({
            type: "DELETE_COURSE_LECTURES_REQUEST"
        })

        const {data} = await axios.delete(`${server}/lecture?courseId=${courseId}&lectureId=${lectureId}`, {
            withCredentials: true
        });

        dispatch({
            type: "DELETE_COURSE_LECTURES_SUCCESS",
            payload: data.message
        })
    } catch (error) {
        dispatch({
            type: 'DELETE_COURSE_LECTURES_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const getAllUsers = ()=> async(dispatch)=>{
    try {
        dispatch({
            type: "ALL_USERS_REQUEST"
        })

        const {data} = await axios.get(`${server}/admin/users`, {
            withCredentials: true
        });

        dispatch({
            type: "ALL_USERS_SUCCESS",
            payload: data.users
        })
    } catch (error) {
        dispatch({
            type: 'ALL_USERS_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const updateUserRole = (id)=> async(dispatch)=>{
    try {
        dispatch({
            type: "UPDATE_USER_ROLE_REQUEST"
        })

        const {data} = await axios.put(`${server}/admin/user/${id}`,{}, {
            withCredentials: true
        });

        dispatch({
            type: "UPDATE_USER_ROLE_SUCCESS",
            payload: data.message
        })
    } catch (error) {
        dispatch({
            type: 'UPDATE_USER_ROLE_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const deleteUser = (id)=> async(dispatch)=>{
    try {
        dispatch({
            type: "DELETE_USER_REQUEST"
        })

        const {data} = await axios.delete(`${server}/admin/user/${id}`, {
            withCredentials: true
        });

        dispatch({
            type: "DELETE_USER_SUCCESS",
            payload: data.message
        })
    } catch (error) {
        dispatch({
            type: 'DELETE_USER_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const getDashboardStats = (id)=> async(dispatch)=>{
    try {
        dispatch({
            type: "GET_DASHBOARD_STATS_REQUEST"
        })

        const {data} = await axios.get(`${server}/admin/dashboard`, {
            withCredentials: true
        });

        dispatch({
            type: "GET_DASHBOARD_STATS_SUCCESS",
            payload: data
        })
    } catch (error) {
        dispatch({
            type: 'GET_DASHBOARD_STATS_FAILURE',
            payload: error.response.data.message
        })
    }
}