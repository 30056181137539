import { server } from "../store";
import axios from 'axios';

export const getAllCourses = (category="", keyword="")=> async(dispatch)=>{
    try {
        dispatch({
            type: "ALL_COURSES_REQUEST"
        })

        const {data} = await axios.get(`${server}/courses?keyword=${keyword}&category=${category}`);

        dispatch({
            type: "ALL_COURSES_SUCCESS",
            payload: data.courses
        })
    } catch (error) {
        dispatch({
            type: 'ALL_COURSES_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const getCourseLectures = (id)=> async(dispatch)=>{
    try {
        dispatch({
            type: "GET_COURSE_REQUEST"
        })

        const {data} = await axios.get(`${server}/course/${id}`, {
            withCredentials: true
        });

        dispatch({
            type: "GET_COURSE_SUCCESS",
            payload: data.lectures
        })
    } catch (error) {
        dispatch({
            type: 'GET_COURSE_FAILURE',
            payload: error.response.data.message
        })
    }
}