import React , {useEffect} from 'react'
import {Box, Grid, HStack, Heading, Progress, Stack, Text } from '@chakra-ui/react'
import cursor from '../../../assets/images/cursor.png'
import Sidebar from '../Sidebar'
import {RiArrowUpLine, RiArrowDownLine} from'react-icons/ri'
import { DoughnutChart, LineChart } from './Chart.js'
import {useDispatch, useSelector} from 'react-redux';
import { getDashboardStats } from '../../../redux/actions/adminAction'
import Loader from '../../Layout/Loader/Loader'
import MetaData from '../../Layout/MetaData/MetaData'

const DataBox = ({title, qty, qtyPercentage, profit})=>(
  <Box w={["full", "24%"]} boxShadow={"-2px 0 10px rgba(107, 70, 193, 0.5)"} p={"8"} borderRadius={"lg"}>
    <Text children={title} />
    <HStack>
      <Text fontSize={"2xl"} fontWeight={"bold"} children={qty} />
      <HStack>
        <Text children={`${qtyPercentage}%`} />
        {profit ? <RiArrowUpLine color='green' /> : <RiArrowDownLine color='red' />}
      </HStack>
    </HStack>

    <Text children={"Since Last Month"} opacity={"0.6"}/>
  </Box>
)

const Bar = ({title, value, profit})=>(
  <Box py={"4"} px={["0", "20"]}>
    <Heading size={"sm"} children={title} mb={"2"}/>
    <HStack w={"full"} alignItems={"center"}>
      <Text children={profit ? "0%": `-${value}%`} />
      <Progress w={"full"} value={profit ? value : 0} colorScheme='purple'/>
      <Text children={`${value > 100 ? value : 100}%`} />
    </HStack>
  </Box>
)

const Dashboard = () => {

  const dispatch = useDispatch();
  const {
    loading,
    stats,
    usersCount,
    viewsCount,
    subscriptionsCount,
    usersPercentage,
    viewsPercentage,
    subscriptionsPercentage,
    usersProfit,
    viewsProfit,
    subscriptionsProfit
  } = useSelector(state => state.admin)

  useEffect(() => {
    dispatch(getDashboardStats());
  }, [dispatch])
  
  return (
    <>

    <MetaData title="Dashboard" />
    <Grid templateColumns={["1fr", "5fr 1fr"]} minH={"100vh"} css={{cursor:`url(${cursor}), default`}}>
      
      {loading || !stats ? <Loader color='purple.500' /> : (
        <Box boxSizing='border-box' py={"16"} px={["4", "0"]}>
          <Text 
            children={`Last change was on ${String(new Date(stats[11].createdAt)).split("G")[0]}`} 
            opacity={"0.5"} 
            textAlign={"center"} 
          />
          <Heading  children="Dashboard" marginLeft={["0", "16"]} mb={"16"} textAlign={["center", "left"]}/>
          <Stack direction={["column", "row"]} minH={"24"} justifyContent={"space-evenly"}>
            <DataBox title="Views"  qty={viewsCount} qtyPercentage={viewsPercentage} profit={viewsProfit}/>
            <DataBox title="Users"  qty={usersCount} qtyPercentage={usersPercentage} profit={usersProfit}/>
            <DataBox title="Subscription"  qty={subscriptionsCount} qtyPercentage={subscriptionsPercentage} profit={subscriptionsProfit}/>
          </Stack>
          <Box m={["0", "16"]} borderRadius={'lg'} p={["0", "16"]} mt={["4", "16"]} boxShadow={"-2px 0 10px rgba(107, 70, 193, 0.5)"}>
            <Heading children="Views Graph" textAlign={["center", "left"]} size={"md"} pt={["8", "0"]} ml={["0", "16"]} />
          
          {/* line graph */}
          <LineChart views={stats.map(item => (item.views))} />
          
          </Box>
          <Grid templateColumns={["1fr", "2fr 1fr"]}>
            <Box>
              <Heading textAlign={["center", "left"]} size={"md"} children={"Progress Bar"} my={"8"} ml={["0", "16"]}/>
              <Box>
                <Bar title="Views" value={viewsPercentage} profit={viewsProfit}/>
                <Bar title="Users" value={usersPercentage} profit={usersProfit}/>
                <Bar title="Subscriptions" value={subscriptionsPercentage} profit={subscriptionsProfit}/>
              </Box>
            </Box>

            <Box boxSizing='border-box' py={"8"}>
              <Heading textAlign={"center"} size={"md"} mb={"4"} children="Users" />

              <Box boxSize={["80"]} mx={["auto"]}>
              {/* Doughnut Graph */}
              <DoughnutChart data={[subscriptionsCount, usersCount-subscriptionsCount]} />
              </Box>
              
            </Box>    
          </Grid>
        </Box>
      )}
      <Sidebar />
  </Grid>
    </>
  )
}

export default Dashboard