import React from 'react'
import {Box, Button, Card, CardBody, CardHeader, CardFooter, HStack, Heading, Image, SimpleGrid, Stack, Text, VStack, Avatar} from '@chakra-ui/react'
import './home.css';
import { Link } from 'react-router-dom';
import vg from '../../assets/images/bg.png'
import introVideo from '../../assets/videos/Intro.mp4'

import {CgGoogle, CgYoutube} from 'react-icons/cg'
import {SiCoursera, SiUdemy} from 'react-icons/si'
import {DiAws} from 'react-icons/di'
import {MdLocalLibrary} from 'react-icons/md';
import {RiAwardFill} from 'react-icons/ri';
import {FaUserAlt} from 'react-icons/fa';
import MetaData from '../Layout/MetaData/MetaData';

const Home = () => {
  return (
    <>
      <MetaData title="Home" />
      <section className='home'> 
        <div className='container'>
          <Stack 
            direction={["column", "row"]} 
            height="100%" 
            justifyContent={["center","space-between"]} 
            alignItems={"center"}
            spacing={["16", "46"]}
            >
              <VStack width={"full"} alignItems={['center', 'flex-start']} spacing="5">  
                  <Heading children="Learn with Course Compass"  size={"xl"} />
                  <Text 
                    textAlign={["center", "left"]} 
                    children="Are you ready to take your learning journey to the next level? Unlock your potential with Course Compass Subscription!" 
                    fontFamily={"cursive"}
                    fontSize={"md"}
                  />
                  <Link to="/courses">
                      <Button size={"lg"} colorScheme='yellow'>Explore Now</Button>
                  </Link>
              </VStack>
              
              <Image className='vector-graphics' boxSize={'md'} src={vg} objectFit="contain"/>

            </Stack>
        </div>

        <Box padding={8} bg="blackAlpha.800">
          <Heading 
            textAlign={"center"} 
            children="OUR BRANDS" 
            color={"yellow.400"}
            fontFamily="body"
          />

          <HStack className='brandsBanner' justifyContent={'space-evenly'} marginTop="4">
            <CgGoogle />
            <CgYoutube />
            <SiUdemy />
            <SiCoursera />
            <Heading color={"white"} size={"2xl"}>
              <DiAws />
            </Heading>
          </HStack>
        </Box>

        <div className="container2">
          <video 
            autoPlay 
            controls 
            controlsList='nodownload nofullscreen noremoteplayback' 
            disablePictureInPicture
            disableRemotePlayback
            src={introVideo}>
            
          </video>
        </div>

        <Box bg={"gray.100"} py={"10"}>
          <VStack>
            <Text children="Why Course Compass?" fontWeight={"bold"} textTransform={"uppercase"} color={"yellow.500"}  fontSize={"xl"}/>
            <Heading color={"black"} children="Making learning easier and more convenient for you." size={["md",  "2xl"]} textAlign={"center"} px={["30",  "60"]}  />
            <SimpleGrid spacing={4}  templateColumns={["1fr", "3fr 3fr 3fr"]} px={"20"} my={"10"} textAlign={"center"}>
              <CardBox icon={<MdLocalLibrary />} color={"yellow.300"} title={"Extensive Course Library"} description={"Gain access to a vast collection of courses spanning various disciplines, from technology and business to arts and personal development."} />
              <CardBox icon={<RiAwardFill />} color={"green.300"} title={"Flexible Learning"} description={"Learn at your own pace, on your own schedule. Course Compass allow you to explore multiple courses simultaneously and revisit the content whenever you need."} />
              <CardBox icon={<FaUserAlt />} color={"purple.300"} title={"Expert Instructors"} description={"Our courses are crafted and taught by industry experts and passionate educators with years of practical experience and get benefitted from their knowledge."} />
              <CardBox icon={<RiAwardFill />} color={"red.300"} title={"Interactive Learning Experience"} description={"Learning should be exciting and engaging. Our courses are designed with interactive elements to keep you motivated throughout your journey."} />
              <CardBox icon={<FaUserAlt />} color={"blue.300"} title={"Community and Support"} description={"Learning is better together. Connect with learners throughout our community forums, engage in discussions and seek assistance from instructors."} />
              <CardBox icon={<MdLocalLibrary />} color={"pink.300"} title={"Continuous Updates"} description={"Knowledge evolves and so do our courses. We regularly update and expand it remains relevant and aligned with the latest trends and development."} />
            </SimpleGrid>
          </VStack>
        </Box>

        <Box px={["0","10"]} m="10">
            <Stack direction={["column","row"]} justifyContent={"space-between"} alignItems={"center"} borderBottom={"1px solid black"} py={"6"}>
              <HStack>
                <Heading children={"Meet your"} />
                <Heading children={"Creator"} color={"yellow.500"} />
              </HStack>
              <Link to="/about">
                <Button size={"lg"} colorScheme='yellow'>Know More</Button>
              </Link>
            </Stack>
            <VStack my="16">
              <Avatar src="https://res.cloudinary.com/devwebcloud/image/upload/v1690130795/SubscriptionProject/UserAvatar/snrk2gtxlsometdkmk0q.png"  boxSize={["40", "80"]}/>
              <Heading children={"Khushi Purwar"} />
              <Text color={"gray.500"} fontWeight={"medium"}>Co-Founder, Course Compass</Text>
            </VStack>
        </Box>
      </section>
    </>
  )
}

export default Home

function CardBox({icon, title, description, color}){
  return (
    <Card className='card' borderBottom='8px' borderColor={color}>
      <CardHeader>
        <VStack>
          <Heading color={color}>
            {icon}
          </Heading>
          <Heading size='md'> {title}</Heading>
        </VStack>
      </CardHeader>
      <CardBody>
        <Text color={"rgb(119,124,133)"} fontWeight={"normal"}>{description}</Text>
      </CardBody>
    </Card>
  )
}