import { Box, Button, Grid, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text, VStack } from '@chakra-ui/react'
import React, {useState} from 'react'
import {RiDeleteBin7Fill} from 'react-icons/ri';
import { fileUploadCss } from '../../Auth/Register';

const CourseModal = ({
    isOpen, 
    onClose, 
    id, 
    deleteLectureHandler, 
    addLectureHandler, 
    courseTitle, 
    lectures = [],
    loading
}) => {

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [video, setVideo] = useState("");
    const [videoPreview, setVideoPreview] = useState("");

    const changeVideoHandler = (e)=>{
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = ()=>{
            setVideoPreview(reader.result)
            setVideo(file)
        }
    }

    const closeHandler = ()=>{
        setDescription("");
        setTitle("");
        setVideoPreview("");
        setVideo("");
        onClose();
    }

  return (
    <Modal isOpen={isOpen} onClose={closeHandler} size={"full"} scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>{courseTitle}</ModalHeader>
            <ModalCloseButton onClick={onClose} />
            <ModalBody p="16">
                <Grid templateColumns={["1fr", "3fr 1fr"]}>
                    <Box px={["0", "16"]}>
                        <Box my="5">
                            <Heading children={courseTitle} />
                            <Heading children={`#${id}`} size={"sm"} opacity={"0.4"} />
                        </Box>
                        <Heading children={"Lectures"} size={"lg"} />
                        {
                            lectures && lectures.length > 0 ? (
                                lectures.map((item, index) => (
                                    <VideoCard 
                                    key={index}
                                    title={item.title} 
                                    description={item.description} 
                                    num={index + 1} 
                                    lectureId={item._id} 
                                    courseId={id} 
                                    deleteHandler={deleteLectureHandler} 
                                    loading={loading}
                                />
                                ))
                            ) : (
                                <Text children="No Lectures Added" color='yellow.500' mt={"4"} fontWeight={"medium"} fontSize={"xl"} />
                            )
                        }
                        {}
                        
                    </Box>

                    {/* Add lecture form */}
                    <Box position={["relative", "relative", "relative", "relative", "fixed" ]} right={["0", "0", "0", "0", "50px"]}>
                        <form onSubmit={e=> addLectureHandler(e, id, title, description, video)}>
                            <VStack spacing={"4"}>
                                <Heading children={"Add Lecture"} size={"md"} textTransform={"uppercase"} />
                                
                                <Input
                                    focusBorderColor='purple.300'
                                    placeholder='Title'
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                                <Input
                                    focusBorderColor='purple.300'
                                    placeholder='Description'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />

                                <Input 
                                    accept='video/mp4'
                                    required 
                                    type='file' 
                                    focusBorderColor='purple.300' 
                                    css={{
                                    "&::file-selector-button":{
                                        ... fileUploadCss,
                                        color: "purple"
                                    }
                                    }} 
                                    onChange={changeVideoHandler}
                                />
                                {videoPreview && (
                                    <Box width={"60"} position={"relative"}>
                                    
                                        <video src={videoPreview} controls controlsList='nodownload'></video>
                                
                                    </Box>
                                )}
                                

                                <Button w={"full"} colorScheme={"purple"} type='submit'>Upload Lecture</Button>
                            </VStack>
                        </form>
                    </Box>
                </Grid>
            </ModalBody>

            <ModalFooter>
                <Button onClick={closeHandler}>Close</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
  )
}

export default CourseModal;

function VideoCard({title, description, num, lectureId, courseId, deleteHandler, loading}){
    return (
        <Stack 
            direction={["column", "row"]}
            justifyContent={["flex-start" , "space-between"]} 
            my={"8"} 
            borderRadius={"lg"} 
            boxShadow={"0 0 10px rgba(107, 70, 193, 0.5)"}
            p={["4", "8"]}
        >
            <Box>
                <Heading size={"sm"} children={`#${num} ${title}`} />
                <Text children={description} />
            </Box>

            <Button color={"purple.600"} onClick={() => deleteHandler(courseId, lectureId)}>
                <RiDeleteBin7Fill fontSize={"2xl"}/>
            </Button>
        </Stack>
    )
}
