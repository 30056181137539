import React from 'react'
import {Container, Heading, ListItem, OrderedList, Text, UnorderedList, VStack} from '@chakra-ui/react'
import {Link} from 'react-router-dom';
import MetaData from '../Layout/MetaData/MetaData';

const TermsConditions = () => {
  return (
    <>
        <MetaData title="Terms & Conditions" />
        <Container minH={'95vh'} maxW={'container.lg'} py={'12'}>
            <Heading children={"Terms of Use"} mb={"12"} />

            <VStack spacing={"10"} fontWeight={"medium"}>
                <Text
                    align={"justify"}
                    children="These Terms of Use set out the terms and conditions for use of this Course Compass (“Website”) and any content, Public Forums, or services offered on or through the Website and/or through any mobile application(s) (“Application”) (collectively referred to as the “Platform”)."
                />

                <Text align={"justify"}>
                    These Terms of Use apply to end users of the Website (referred to as “Learners”, “You”, “Your”). These Terms of Use, including the&nbsp;
                    <Link to='/privacyPolicy'className='link'>
                        Privacy Policy
                    </Link>
                    &nbsp;and any other terms and conditions published on the Platform or communicated to you from time to time (collectively referred to as the “Agreement”), define the relationship and responsibilities between You and Creator (as defined herein) in using the Platform. Your access to the Platform is subject to Your acceptance of this Agreement. Hence, please take Your time to read this Agreement.
                </Text>

                <Text 
                align={"justify"}
                children="By accessing this Platform, You are agreeing to be bound by the terms of this Agreement, all applicable laws and regulations. From time-to-time, versions of the above-mentioned policies and terms are made available on the Platform for Your reference and to understand how we handle Your personal information. By using or visiting the Platform and services provided to You on, from, or through the Platform, You are expressly agreeing to the terms of the Agreement and any other terms that are updated from time to time."
                />

                <Text 
                align={"justify"}
                children="If You disagree with any part of this Agreement or do not wish to be bound by the same, then please do not use the Platform in any manner."
                />

                <VStack alignItems={"flex-start"}>
                    <OrderedList spacing={"4"}>
                        <ListItem>Access and Registration: </ListItem>
                        <Text fontWeight={"normal"} align={"justify"}
                        children="If You’re an individual You must be at least 18 (eighteen) years of age, or, if You are between the ages of 13 and 18, You must have Your parent or legal guardian's permission to use the Platform. By using the Platform, You are, through Your actions, representing and warranting to us that You have obtained the appropriate consents/permissions to use the Platform. If You are under the age of 13 years or 16 years (depending on your country of reisdence), You may neither use our Platform in any manner nor may You register for any content or services offered therein"
                        />
                        <Text fontWeight={"normal"} align={"justify"}
                        children="To access any Content (as defined below) offered on the Platform, we require You to register for the same by providing Your name and email address. Please read our Privacy Policy to understand how we handle Your information. Further, You may also be required to make payment of a fee to access the Content, if applicable. For more information, please read our ‘Payments & Refunds’ section below. You represent that the information indicated by You during Your enrolment or registration for any Content on the Platform, is true and complete, that You meet the eligibility requirements for use of the Platform and the Content offered therein, and You agree to update us upon any changes to the information by writing to us at the contact information provided in the ‘Contact Us’ section below."
                        />
                        <Text fontWeight={"normal"} align={"justify"}
                        children="Further, to access the Platform and/or view the content on the Platform, You will need to use a “Supported/Compatible Device” which means a personal computer, mobile phone, portable media player, or other electronic device that meets the system and compatibility requirements and on which You are authorized to operate the Platform. The Supported/Compatible Devices to access the Platform may change from time to time and, in some cases, whether a device is (or remains) a Supported/Compatible Device may depend on software or systems provided or maintained by the device manufacturer or other third parties. Accordingly, devices that are Supported/Compatible Devices at one time may cease to be Supported/Compatible Devices in the future. Thus, kindly make sure that the device that You use is compatible with any of the systems/software used on the Platform to access the content/material offered therein."
                        />

                        <ListItem>Communications: </ListItem>
                        <Text fontWeight={"normal"} align={"justify"}
                            children="The Platform includes provision and facilitation of Public Forums designed to enable You to communicate with us and other registrants to the Content You have registered for. As stated above, use of these Public Forums are completely your choice and by registering for a Content, you are not obligated to participate in the Public Forum. However, if You choose to participate, You agree to adhere to the terms specified in the ‘Code of Conduct’ section hereinbelow and such other terms as may be published on our Platform. If it comes to our notice that Your conduct is in violation of the terms of this Agreement, then we may terminate or suspend Your access to any Public Forums at any time, without notice, for any reason. You represent and warrant that You own and control all rights in and to any content (including without limitation chats, postings, or materials) uploaded or posted by You on the Public Forums or anywhere on the Platform (“Learner Content”), or that You are licensed to use and reproduce such Learner Content. We are not responsible for the information that You choose to share on the Public Forums, or for the actions of other users therein. You further understand and agree that You, shall be solely responsible for the Learner Content including its legality, reliability, accuracy, and appropriateness, and the consequences of its publication."
                        />
                        <Text fontWeight={"normal"} align={"justify"}
                        children="Further, if you do post content or submit any Learner Content on the Platform, and unless otherwise indicated by You in writing (emails included) to us, You hereby grant us a non-exclusive, royalty-free, irrevocable, perpetual and fully sublicensable rights to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such Learner Content throughout the world in any media."
                        />

                        <ListItem>License to Use: </ListItem>
                        <Text fontWeight={"normal"} align={"justify"}
                            children="You are granted a limited, non-exclusive license to access and view the Content on the Platform for Your own personal, non-commercial use only. Further, if so allowed on the Platform, You may temporarily download one copy of any downloadable Content [including Creator Content (defined below)] on the Platform for personal and non-commercial transitory viewing only."
                        />
                        <Text fontWeight={"normal"} align={"justify"}
                        children="This license does not grant You the right to assign or sublicense the license granted under this Agreement to anyone else. Further, You may not-"
                        />
                        <UnorderedList >
                            <ListItem fontWeight={"normal"}>modify, edit or copy the Content, Creator Content or any material made available on the Platform;</ListItem>
                            <ListItem fontWeight={"normal"}>create derivative works or exploit any material made available on the Platform (including the Content and Creator Content) or any portion thereof (including, but not limited to any copyrighted material, trademarks, or other proprietary information contained therein) in manner that is not permitted under this license granted to You;</ListItem>
                            <ListItem fontWeight={"normal"}>publicly display (commercially or non-commercially) the Content, Creator Content or any material made available on the Platform or otherwise use the same for any commercial purpose;</ListItem>
                            <ListItem fontWeight={"normal"}>attempt to decompile or reverse engineer any software contained in the Platform;</ListItem>
                            <ListItem fontWeight={"normal"}>remove any copyright or other proprietary notations from the Content, Creator Content or any material made available on the Platform;</ListItem>
                        </UnorderedList>

                        <Text fontWeight={"normal"} align={"justify"}
                            children="This license shall automatically terminate if You violate any of these restrictions and may be terminated by us at any time. Upon termination of this license granted to You or Your viewing of any material on the Platform (including Content and Creator Content), You must destroy any downloaded materials in Your possession (whether in electronic or printed format)."
                        />

                        <ListItem>Feedback: </ListItem>
                        <Text fontWeight={"normal"} align={"justify"}
                            children="If You submit suggestions, ideas, comments, or questions containing product feedback about any Content, the Platform or any part thereof, either through the Public Forum or otherwise (“Feedback”), then You grants to us a worldwide, non-exclusive, royalty-free, perpetual, and irrevocable right to use (and full right to sublicense), reproduce, modify, adapt, publish, translate, create derivative works from, distribute, transmit, and display such Feedback in any form. You shall have no intellectual property right in any Content, Platform or any part thereof, as a result of our incorporation of Feedback into any Content or the Platform."
                        />

                        <ListItem>Limitation of Liability: </ListItem>
                        <Text fontWeight={"normal"} align={"justify"}
                            children="In no event shall the Creator be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the Content or any other materials on the Platform, even if the Creator or any authorized personnel of the Creator has been notified orally or in writing of the possibility of such damage. Some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, so, some or all of these limitations may not apply to You."
                        />

                        <ListItem>Code of Conduct: </ListItem>
                        <Text fontWeight={"normal"} align={"justify"}
                            children="You agree to the following:"
                        />
                        <UnorderedList spacing={"4"}>
                            <ListItem>Legitimate usage of the Platform:</ListItem>
                            <Text fontWeight={"normal"} align={"justify"}
                                children="You agree to use the Platform only for lawful purposes and You are not allowed to use our Platform to engage in any kind of activity that violates any applicable central, state, local, federal or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the USA or other countries). Also, You agree that You will not use the Platform in any manner that would disrupt, damage or impair the Platform or access to it, in any manner, including promoting or encouraging illegal activity such as hacking, cracking or distribution of counterfeit software, compromised accounts, or cheats or hacks for the Platform and conduct of any form of fraudulent activity."
                            />
                            <ListItem>No harmful or dangerous content:</ListItem>
                            <Text fontWeight={"normal"} align={"justify"}
                                children="Any content which incites or promotes violence, that may cause physical or emotional harm or that may endanger the safety of any individual or is otherwise objectionable is expressly prohibited on the Platform. The Platform is to be used only for the permitted uses as detailed under this Agreement."
                            />
                            <ListItem>No hateful or defamatory content:</ListItem>
                            <Text fontWeight={"normal"} align={"justify"}
                                children="We realise that there may be instances when there are exchange of ideas and opinions which is essential in the learning process, while we agree that individuals have the right to voice their opinion, we do not encourage or tolerate any form of hate speech or statements that are libelous, slanderous, threatening, violent, predatory, defamatory, or any statement that incites hatred against specific individuals or groups with respect to but not limited to race or ethnic origin, country caste, religion, disability, gender, age, sexual orientation/gender identity etc."
                            />
                            <ListItem>Harassment and bullying:</ListItem>
                            <Text fontWeight={"normal"} align={"justify"}
                                children="We do not tolerate any form of harassment or bullying on the Platform and strive to keep the Platform a safe space to foster learning. Harassment in this case would include, without limitation, to abusive videos, comments, messages, revealing someone’s personal information, including sensitive personally identifiable information of individuals, content or comments uploaded in order to humiliate someone, sexual harassment or sexual bullying in any form."
                            />
                            <ListItem>Unauthorized Access or Disabling of Platform::</ListItem>
                            <Text fontWeight={"normal"} align={"justify"}
                                children="You agree not to (i) use the Platform in any manner that could disable, overburden, damage, or impair the Platform or interfere with any other user’s use of the Platform; (ii) not to use any manual process to monitor or copy any of the material on the Platform or for any unauthorized purpose; (iii) use any device, software, or routine that interferes with the proper working of the Platform; (iv) attack the Platform via a denial-of-service attack; (v) attempt to gain unauthorized access to, interfere with, or disrupt any parts of the Platform, the server on which the Platform is stored, or any server, computer, or database connected to or associated with the Platform; and (vi) introduce any viruses, trojan horses, worms, keystroke loggers, malware, or other material which is malicious or technologically harmful to the Platform."
                            />
                        </UnorderedList>

                        <ListItem>Disclaimer: </ListItem>
                        <Text fontWeight={"normal"} lineHeight="7" align={"justify"}
                            children="THE PLATFORM IS PROVIDED TO YOU 'AS IS' AND 'AS AVAILABLE' AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, THE CREATOR, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, WITH RESPECT TO THE PLATFORM, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OR TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, THE CREATOR PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE PLATFORM OR THE SERVICES OR PRODUCTS OFFERED THEREIN WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS OR BE ERROR FREE OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED."
                        />

                        <Text fontWeight={"normal"} lineHeight="7" align={"justify"}
                            children="WITHOUT LIMITING THE FOREGOING, THE CREATOR MAKES NO REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED: (I) AS TO THE OPERATION OR AVAILABILITY OF THE PLATFORM, OR THE INFORMATION, CONTENT, AND MATERIALS OR PRODUCTS INCLUDED THEREON; (II) THAT THE PLATFORM WILL BE UNINTERRUPTED OR ERROR-FREE; (III) AS TO THE ACCURACY, RELIABILITY, OR CURRENCY OF ANY INFORMATION OR CONTENT PROVIDED THROUGH THE PLATFORM; OR (IV) THAT THE PLATFORM, ITS SERVERS, THE CONTENT, OR E-MAILS SENT FROM OR ON BEHALF OF THE CREATOR ARE FREE OF VIRUSES, SCRIPTS, TROJAN HORSES, WORMS, MALWARE, TIMEBOMBS OR OTHER HARMFUL COMPONENTS."
                        />

                        <ListItem>Contact Us: </ListItem>
                        <Text fontWeight={"normal"} lineHeight="7" align={"justify"}>
                            If You’ve have concerns or queries regarding this Agreement, You may write to us by email at&nbsp;
                            <Link to='mailto:support@coursecompass.in' className='link'>
                            support@coursecompass.in
                            </Link>
                            
                        </Text>

                    </OrderedList>
                    
                </VStack>

                <VStack alignItems={"flex-start"} spacing={"8"}>
                    
                    
                </VStack>
            </VStack>
        </Container>
    </>
  )
}

export default TermsConditions