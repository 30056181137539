import { createReducer } from "@reduxjs/toolkit";

export const profileReducer = createReducer({}, {
    UPDATE_PROFILE_REQUEST: (state)=>{
        state.loading = true;
    },
    UPDATE_PROFILE_SUCCESS: (state, action)=>{
        state.loading = false;
        state.message = action.payload;
    },
    UPDATE_PROFILE_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    CHANGE_PASSWORD_REQUEST: (state)=>{
        state.loading = true;
    },
    CHANGE_PASSWORD_SUCCESS: (state, action)=>{
        state.loading = false;
        state.message = action.payload;
    },
    CHANGE_PASSWORD_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    UPDATE_PROFILE_PICTURE_REQUEST: (state)=>{
        state.loading = true;
    },
    UPDATE_PROFILE_PICTURE_SUCCESS: (state, action)=>{
        state.loading = false;
        state.message = action.payload;
    },
    UPDATE_PROFILE_PICTURE_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    CLEAR_ERROR: (state)=>{
        state.error = null;
    },
    CLEAR_MESSAGE: (state)=>{
        state.message = null;
    }
})