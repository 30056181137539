import { createReducer } from "@reduxjs/toolkit";

export const courseReducer = createReducer({courses: [], lectures: []}, {
    ALL_COURSES_REQUEST: (state)=>{
        state.loading = true;
    },
    ALL_COURSES_SUCCESS: (state, action)=>{
        state.loading = false;
        state.courses = action.payload;
    },
    ALL_COURSES_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    GET_COURSE_REQUEST: (state)=>{
        state.loading = true;
    },
    GET_COURSE_SUCCESS: (state, action)=>{
        state.loading = false;
        state.lectures = action.payload;
    },
    GET_COURSE_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    ADD_TO_PLAYLIST_REQUEST: (state)=>{
        state.loading = true;
    },
    ADD_TO_PLAYLIST_SUCCESS: (state, action)=>{
        state.loading = false;
        state.message = action.payload;
    },
    ADD_TO_PLAYLIST_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    CLEAR_ERROR: (state)=>{
        state.error = null;
    },
    CLEAR_MESSAGE: (state)=>{
        state.message = null;
    }
})