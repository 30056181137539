import React , {useEffect} from 'react'
import {Box, Grid, Heading, Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import cursor from '../../../assets/images/cursor.png'
import Sidebar from '../Sidebar'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../../Layout/Loader/Loader';
import {toast} from 'react-hot-toast'
import { getAllRequests } from '../../../redux/actions/additionalAction';
import MetaData from '../../Layout/MetaData/MetaData';

const Requests = () => {
  const dispatch = useDispatch();
  const {loading, requests, error, message} = useSelector(state => state.other)

  useEffect(() => {

    dispatch(getAllRequests());

    if(error){
      toast.error(error);
      dispatch({
        type: "CLEAR_ERROR"
      })
    }

    if(message){
      toast.success(message);
      dispatch({
        type: "CLEAR_MESSAGE"
      })
    }

  }, [dispatch, error, message]);
  

  return (
     <>
        <MetaData title="Admin Requests" />
        <Grid templateColumns={["1fr", "5fr 1fr"]} minH={"100vh"} css={{cursor:`url(${cursor}), default`}}>
          {loading ? 
          <Loader color='purple.500' /> :
          (
              <Box p={["0", "12"]} overflowX={"auto"}>
              <Heading children="All Requests" textTransform={'uppercase'} my={'10'} textAlign={["center", "left"]} />
                  <TableContainer w={["100vw", "full"]}>
                      <Table variant={"simple"} size={"lg"}>
                      <TableCaption opacity={"0.7"} >All Available Requests present in the database.</TableCaption>
                      <Thead>
                          <Tr>
                          <Th>Name</Th>
                          <Th>Email</Th>
                          <Th>Requests</Th>
                          </Tr>
                      </Thead>
                      <Tbody>
                          {requests && requests.map(item => (
                          <Row  key={item._id} item={item} />
                          ))}
                      </Tbody>
                      </Table>
                  </TableContainer>
              </Box>
              )}
          <Sidebar />
        </Grid>
     </>
 )
}

export default Requests

function Row ({item}){
    return (
    <Tr>
        <Td>{item.name}</Td>
        <Td>{item.email}</Td>
        <Td>{item.message}</Td> 
      </Tr>
    )
}