import React from 'react'
import {ColorModeSwitcher} from '../../../ColorModeSwitcher';
import {RiMenu5Fill, RiLogoutBoxLine, RiDashboardFill} from 'react-icons/ri';
import { Button, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, HStack, VStack, useDisclosure } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import {useDispatch} from 'react-redux'
import { logout } from '../../../redux/actions/userAction';

const LinkButton = ({url='/', title="Home", onClose})=>(
    <Link to={url} onClick={onClose}>
        <Button variant={'ghost'}>{title}</Button>
    </Link>
)

const Header = ({isAuthenticated = false, user}) => {
    const {isOpen, onClose, onOpen} = useDisclosure();

    const dispatch = useDispatch();
    const logoutHandler = () => {
        onClose();
        dispatch(logout());
    }
    return (
        <>
        <ColorModeSwitcher />

        <Button 
            colorScheme={'yellow'} 
            width="12" 
            height="12" 
            rounded={'full'} 
            position={"fixed"} 
            left="6" 
            top="6"
            onClick={onOpen}
            zIndex={'overlay'}
            >
            <RiMenu5Fill />
        </Button>

        <Drawer placement='left' isOpen={isOpen} onClose={onClose}>
            <DrawerOverlay backdropFilter={'blur(3px)'} />
            <DrawerContent>
                <DrawerHeader borderBottomWidth="1px">
                    Course Compass
                </DrawerHeader>
                <DrawerBody>
                    <VStack spacing={"4"} alignItems="flex-start">
                        <LinkButton url="/" title="Home" onClose={onClose} />
                        <LinkButton url="/about" title="About Us" onClose={onClose} />
                        <LinkButton url="/courses" title="Browse All Courses" onClose={onClose} />
                        <LinkButton url="/request" title="Request A Course" onClose={onClose} />
                        <LinkButton url="/contact" title="Contact Us" onClose={onClose} />
                        <HStack 
                            justifyContent={"space-evenly"} 
                            position={"absolute"} 
                            bottom={"2rem"} 
                            width="80%"
                        >
                        {isAuthenticated ? (
                            <>
                            <VStack>
                                <HStack>
                                    <Link to="/myProfile" onClick={onClose}>
                                        <Button colorScheme='yellow' variant={"ghost"}>My profile</Button>
                                    </Link>
                                    <Button variant={"ghost"} onClick={logoutHandler}>
                                        <RiLogoutBoxLine />
                                        Logout
                                    </Button>
                                </HStack>

                                {
                                    user && user.role==="admin" && <Link onClick={onClose} to="/admin/dashboard">
                                        <Button variant={'ghost'} colorScheme='purple'>
                                            <RiDashboardFill style={{margin: '4px'}} />
                                            Dashboard
                                        </Button>
                                    </Link>
                                }
                            </VStack>
                            </>
                        ) : (
                            <>
                            <Link to="/login" onClick={onClose}>
                                <Button colorScheme='yellow'>Login</Button>
                            </Link>
                            <p>OR</p>
                            <Link to="/register" onClick={onClose}>
                                <Button colorScheme='yellow'>Sign Up</Button>
                            </Link>
                            </>
                        )}
                        </HStack>
                    </VStack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
        </>
    )
}

export default Header