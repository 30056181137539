import { Box, Button, Container, FormLabel, Heading, Input, Textarea, VStack } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import { contact } from '../../redux/actions/additionalAction';
import {toast} from 'react-hot-toast';
import MetaData from '../Layout/MetaData/MetaData';

const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const dispatch = useDispatch();
    const {loading , error, message:contactMessage} = useSelector(state => state.other)

    const submitHandler = async(e)=>{
        e.preventDefault();
        await dispatch(contact(name, email, message));
    }

    useEffect(() => {
        if(error){
           toast.error(error);
           dispatch({
             type: "CLEAR_ERROR",
           })
        }
      
        if(contactMessage){
            toast.success(contactMessage);
            dispatch({
              type: "CLEAR_MESSAGE",
            })
            setName("");
            setEmail("");
            setMessage("");
          }
    }, [dispatch, error, contactMessage]);

  return (
    <>
        <MetaData title="Contact Us" />
        <Container h={'120vh'}>
            <VStack h={'full'} justifyContent={'center'} spacing={'12'}>
                <Heading children={'Contact Us'} marginTop={'10'} />
                <form style={{width:'100%'}} onSubmit={submitHandler}>

                    <Box marginY={"4"}>
                        <FormLabel htmlFor='name' children="Name" />
                        <Input 
                            required 
                            id='name' 
                            value={name} 
                            onChange={(e) => setName(e.target.value)}
                            placeholder='abc'
                            type='text'
                            focusBorderColor='yellow.500' 
                        />
                    </Box>

                    <Box marginY={"4"}>
                        <FormLabel htmlFor='email' children="Email Address" />
                        <Input 
                            required 
                            id='email' 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='abc@example.com'
                            type='email'
                            focusBorderColor='yellow.500' 
                        />
                    </Box>

                    <Box marginY={"4"}>
                        <FormLabel htmlFor='message' children="Message" />
                        <Textarea 
                            required 
                            id='message' 
                            value={message}  
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder='Your course is awesome.'
                            focusBorderColor='yellow.500' 
                        />
                    </Box>
                    <Button my="4" colorScheme='yellow' type='submit' w={'full'} isLoading={loading} loadingText="Sending...">Send Message</Button>

                    <Box my={'4'}>
                        Request For A Course? <Link to='/request'>
                            <Button variant={'link'} colorScheme='yellow'>Send Request Here</Button>
                        </Link>
                    </Box>
                </form>
            </VStack>
        </Container>
    </>
  )
}

export default Contact