import { Button, Container, Heading, VStack } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import {RiErrorWarningFill} from 'react-icons/ri'
import MetaData from '../Layout/MetaData/MetaData';

const PaymentFailure = () => {
  return (
    <>
      <MetaData title="Payment Failure" />
      <Container h={'110vh'} >
          <VStack justifyContent={'center'} h="full" spacing={'4'}>
              <RiErrorWarningFill color='red' size={'6rem'} />
              <Heading textTransform={'uppercase'}>Payment Failed!</Heading>
              <Link to="/subscribe">
                  <Button variant={'ghost'}  bg={'yellow.400'}>Try Again!</Button>
              </Link>
          </VStack>

      </Container>
    </>
  )
}

export default PaymentFailure