import {Box, Button, Container, FormLabel, Heading, Input, VStack } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {changePassword} from '../../redux/actions/profileAction.js'
import { toast } from 'react-hot-toast'
import { Link } from 'react-router-dom';
import MetaData from '../Layout/MetaData/MetaData';

const ChangePassword = () => {

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const dispatch = useDispatch();

    const changePasswordHandler = (e)=>{
        e.preventDefault();
       
        dispatch(changePassword(oldPassword , newPassword));
    }

    const { loading, message, error } = useSelector(state => state.profile);
    useEffect(() => {
      if(error){
        toast.error(error);
        dispatch({
            type: "CLEAR_ERROR"
          })
        setOldPassword("");
        setNewPassword("");
      }
      if(message){
        toast.success(message);
        dispatch({
          type: "CLEAR_MESSAGE"
        })
        setOldPassword("");
        setNewPassword("");
      }
    }, [dispatch, message, error]);
    

  return (
    <>
      <MetaData title="Change Password" />
      <Container h={'90vh'}>
          <VStack h={'full'} justifyContent={'center'} spacing={'16'}>
              <Heading children={'Change Password'} textTransform={'uppercase'} textAlign={["center", "left"]} />
              <form style={{width:'100%'}}  onSubmit={changePasswordHandler}>

                  <Box marginY={"4"}>
                      <FormLabel htmlFor='oldPassword' children="Old Password" />
                      <Input 
                          required 
                          id='oldPassword' 
                          value={oldPassword} 
                          onChange={(e) => setOldPassword(e.target.value)}
                          placeholder='*************'
                          type='password'
                          focusBorderColor='yellow.500' 
                      />
                  </Box>

                  <Box marginY={"4"}>
                      <FormLabel htmlFor='newPassword' children="New Password" />
                      <Input 
                          required 
                          id='newPassword' 
                          value={newPassword} 
                          onChange={(e) => setNewPassword(e.target.value)}
                          placeholder='*************'
                          type='password'
                          focusBorderColor='yellow.500' 
                      />
                  </Box>

                  <Button isLoading={loading} my="4" colorScheme='yellow' type='submit' w={'full'}>Change</Button>
                  <Link to="/myProfile">
                    <Button isLoading={loading} my="5" variant={"link"} colorScheme='yellow'>Go to Profile</Button>
                  </Link>
              </form>
          </VStack>
      </Container>
    </>
  )
}

export default ChangePassword