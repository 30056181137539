import { Box, Button, Grid, HStack, Heading, Text, VStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Container, useDisclosure, Input, FormLabel, Stack, Avatar } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useParams, Navigate} from 'react-router-dom';
import { getCourseLectures } from '../../redux/actions/courseAction';
import Loader from '../Layout/Loader/Loader';
import ReactStars from "react-rating-stars-component";
import {RiDeleteBinFill} from 'react-icons/ri'
import { addLectureReview, deleteLectureReview } from '../../redux/actions/reviewAction';
import {toast} from 'react-hot-toast';
import MetaData from '../Layout/MetaData/MetaData';

const CoursePage = () => {
    const [lectureNumber, setLectureNumber] = useState(0);
    const [lectureId, setLectureId] = useState("");
    const dispatch = useDispatch();
    const params = useParams();
    const {isOpen, onClose, onOpen} = useDisclosure();

    const {lectures, loading} = useSelector(state => state.course)
    const {error, message, loading: reviewLoading} = useSelector(state => state.review)
    const {user} = useSelector(state => state.user);

    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
              type: "CLEAR_ERROR"
            })
          }
      
          if(message){
            toast.success(message);
            dispatch({
              type: "CLEAR_MESSAGE"
            })
          }
      dispatch(getCourseLectures(params.id));
    }, [dispatch, params.id, error, message]);

    if(user.role !== "admin" && (user.subscription === undefined || user.subscription.status !== "active")){
        return <Navigate to={"/subscribe"} />
    }

    const addReviewHandler = (id)=>{
        setLectureId(id)
        onOpen();
    }

    const deleteHandler = (lectureId, reviewId)=>{
        dispatch(deleteLectureReview(params.id, lectureId, reviewId));
    }
    
  return (
    loading ? <Loader /> :
    (
        <>
            <MetaData title={"Lectures"} />
            <Grid minH={'100vh'} templateColumns={["1fr", "3fr 1fr"]} padding={"20"}>
                {
                    lectures && lectures.length > 0 ? (
                    <>
                        <VStack width={"780px"} >
                            <Box>
                                <video
                                width={'100%'}
                                controls 
                                controlsList='nodownload noremoteplayback' 
                                disablePictureInPicture
                                disableRemotePlayback
                                borderRadius={"10"}
                                src={lectures[lectureNumber].video.url}>
                                
                                </video>
                                <Heading children={`Lecture ${lectureNumber+1}: ${lectures[lectureNumber].title}`} m="4" />
                                <Box  borderRadius={"10"} py={"1"}>
                                    <Text children={'Description'} m="4" fontSize={'2xl'} fontWeight={'medium'} />
                                    <Text m="4" children={lectures[lectureNumber].description} />
                                </Box>

                                <HStack justifyContent={"space-between"}>
                                    <Text children={'Reviews'} m="4" fontSize={'2xl'} fontWeight={'medium'} />
                                    <Button colorScheme='yellow' variant={'ghost'} onClick={(e) => addReviewHandler(lectures[lectureNumber]._id)}>Add Review</Button>
                                </HStack>
                                {
                                    lectures[lectureNumber].reviews && lectures[lectureNumber].reviews.length > 0 ? 
                                    (
                                        <Box px={"4"}>
                                            <HStack mb={"4"}>
                                                <Text children={`${lectures[lectureNumber].reviews.length} `} />
                                                <Text children={lectures[lectureNumber].reviews.length === 1 ? "Comment": "Comments"} />
                                            </HStack>
                                            {
                                                lectures[lectureNumber].reviews.map((review, index)=>(
                                                    <Stack direction={['column', 'row']} spacing={['4', '4']} px="2" mb={"2"} key={index} >
                                                        <VStack>
                                                            <Avatar boxSize={['10', '10']} src={review.user.avatar.url}  />
                                                        </VStack>
                                                        <VStack justifyContent={'center'} alignItems={["center", "flex-start"]} >
                                                            <HStack>
                                                                <Text children={`@${review.name}`} fontWeight={"medium"} />
                                                                <Text children={`${review.rating} ★`} color={'yellow.400'}  />
                                                                {review.name === user.name && (<Button variant={"unstyled"}  onClick={(e) => deleteHandler(lectures[lectureNumber]._id, review._id)}>
                                                                    <RiDeleteBinFill color='red'/>
                                                                </Button>)}
                                                            </HStack>
                                                            <Text children={review.comment} />
                                                        </VStack>
                                                    </Stack>
                                                ))
                                            } 
                                        </Box>
                                        
                                        ) : <Text px={"4"} children="No Comments Added!" />
                                }
                                
                            </Box>

                            <ReviewBox isOpen={isOpen} onClose={onClose} courseId={params.id} loading={reviewLoading} lectureId={lectureId}/>

                        </VStack>
                        <VStack height={'433'} border='1px' borderColor='gray.200' borderRadius={"10"} width={"500"} overflowY={"scroll"}>
                            {lectures.map((element, index) =>(
                                <button 
                                key={element._id} style={{
                                    width: "100%",
                                    padding: "1rem",
                                    textAlign: "left",
                                    margin: 0,
                                    borderBottom: "1px solid rgba(0,0,0,0.2"
                                }} onClick={()=> setLectureNumber(index)}>
                                    
                                    <Text noOfLines={'1'}>
                                        Lecture {index+1}: {element.title}
                                    </Text>
                                </button>
                            ))}
                        </VStack>
                    </>
                    ) : (
                        <Box maxW="520px" mt={"40"} textAlign={"center"} ml={["0", "38" , "48" ,"96"]} mb={["0"]} h={"14"} boxShadow={'lg'} w="full" bg="yellow.300" p="2" borderRadius={'lg'}>
                            <Text fontSize={"2xl"}  color="black">No Lectures Present</Text>
                        </Box>
                    )
                }
            </Grid>
        </>
    )
  )
}

export default CoursePage

function ReviewBox({isOpen, onClose, loading, courseId, lectureId}){

    const [comment, setComment] = useState("");
    const [rating, setRating] = useState(0);
    const dispatch =  useDispatch();

    const ratingChanged = (newRating) => {
        setRating(newRating);
    };

    const modalClose = ()=>{
        onClose();
    }

    const reviewHandler = async (e) => {
        const formData = new FormData();
        formData.set("rating", rating);
        formData.set("comment", comment);
        formData.set("lectureId", lectureId)
        await dispatch(addLectureReview(courseId, formData));
        dispatch(getCourseLectures(courseId));
        onClose();
      };

    return (
        <Modal isOpen={isOpen} onClose={modalClose}>
            <ModalOverlay backdropFilter={'blur(10px)'} />
            <ModalContent>
                <ModalHeader>
                    Add Your Review
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Container>
                            <VStack spacing={'2'}>
                                    <Box width={"100%"}>
                                    <FormLabel children="Choose your star rating" />
                                    <ReactStars
                                        count={5}
                                        value={rating}
                                        onChange={ratingChanged}
                                        size={50}
                                        isHalf={true}
                                        emptyIcon={<i className="far fa-star"></i>}
                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                        fullIcon={<i className="fa fa-star"></i>}
                                        activeColor="#fa9c23"
                                        required
                                    />
                                    </Box>
                                    <Box marginY={"4"} width={"100%"}>
                                        <FormLabel htmlFor='comment' children="Enter Review" />
                                        <Input 
                                            required 
                                            id='comment' 
                                            value={comment} 
                                            onChange={(e) => setComment(e.target.value)}
                                            placeholder='Good Content'
                                            type='text'
                                            focusBorderColor='yellow.500' 
                                        />
                                    </Box>
                                    <Button w="full" colorScheme='yellow' type='button' isLoading={loading} onClick={reviewHandler}>Add Review</Button>
                            </VStack>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button mr="3" onClick={modalClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}