import { Box, Button,  Stack, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom';

const LinkButton = ({url='/', title="Home"})=>(
  <Link to={url}>
      <Button variant={'unstyled'} fontWeight={"light"}>{title}</Button>
  </Link>
)

const Footer = () => {
  return (
    <Box bg={"blackAlpha.900"} color={"white"} p={"8"}>
        <Stack justifyContent={"space-evenly"} borderBottom={"1px solid white"} paddingBottom={"4"} direction={["column", "row"]}>

          <VStack alignItems={["center","flex-start"]} w={"300px"}>
            <Text children="Course Compass" fontWeight={"medium"} textTransform={"uppercase"} fontSize={"xl"}/>
            <Text children="Unlock your potential with Course Compass Subscription!" fontWeight={"light"}/>
          </VStack>



          <VStack alignItems={["center","flex-start"]} spacing={"1"}>
            <Text children="Services" fontWeight={"medium"} textTransform={"uppercase"} fontSize={"lg"}/>
            <LinkButton url="/termsConditions" title="Terms & Conditions" />
            <LinkButton url="/privacyPolicy" title="Privacy Policy" />
            <LinkButton url="/cancellationPolicy" title="Refund & Cancellation policy" />
          </VStack>

          <VStack alignItems={["center","flex-start"]}>
            <Text children="Get in Touch" fontWeight={"medium"} textTransform={"uppercase"} fontSize={"lg"}/>
            <Text children="Email: support@coursecompass.in" fontWeight={"light"}/>
          </VStack>
        </Stack>
        <Text textAlign={"center"} mt={"10"} fontSize={"xl"} children="Copyright &copy; 2023. All Rights Reserved." />
    </Box>
  )
}

export default Footer