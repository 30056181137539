import { Button, Container, Heading, VStack } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import {RiErrorWarningFill} from 'react-icons/ri'
import MetaData from '../MetaData/MetaData'

const NotFound = () => {
  return (
    <>
    <MetaData title="NotFound" />
    <Container h={'110vh'} >
        <VStack justifyContent={'center'} h="full" spacing={'4'}>
            <RiErrorWarningFill color='red' size={'6rem'} />
            <Heading>Page Not Found</Heading>
            <Link to="/">
                <Button variant={'ghost'}  bg={'yellow.400'}>Go To Home</Button>
            </Link>
        </VStack>

    </Container>
    </>
  )
}

export default NotFound