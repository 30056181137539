import { server } from "../store";
import axios from 'axios';

export const contact = (name, email, message)=> async(dispatch)=>{
    try {
        dispatch({
            type: "CONTACT_REQUEST"
        })

        const {data} = await axios.post(`${server}/contact`, {name, email, message}, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true
        });

        dispatch({
            type: "CONTACT_SUCCESS",
            payload: data.message
        })
    } catch (error) {
        dispatch({
            type: 'CONTACT_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const courseRequest = (name, email, message)=> async(dispatch)=>{
    try {
        dispatch({
            type: "COURSE_REQ_REQUEST"
        })

        const {data} = await axios.post(`${server}/courseRequest`, {name, email, message}, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true
        });

        dispatch({
            type: "COURSE_REQ_SUCCESS",
            payload: data.message
        })
    } catch (error) {
        dispatch({
            type: 'COURSE_REQ_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const getAllContacts = (name, email, message)=> async(dispatch)=>{
    try {
        dispatch({
            type: "GET_CONTACTS_REQUEST"
        })

        const {data} = await axios.get(`${server}/admin/contacts`, {
            withCredentials: true
        });

        console.log(data);

        dispatch({
            type: "GET_CONTACTS_SUCCESS",
            payload: data.contactMessages
        })
    } catch (error) {
        dispatch({
            type: 'GET_CONTACTS_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const getAllRequests = (name, email, message)=> async(dispatch)=>{
    try {
        dispatch({
            type: "GET_COURSE_REQ_REQUEST"
        })

        const {data} = await axios.get(`${server}/admin/requests`, {
            withCredentials: true
        });

        dispatch({
            type: "GET_COURSE_REQ_SUCCESS",
            payload: data.requests
        })
    } catch (error) {
        dispatch({
            type: 'GET_COURSE_REQ_FAILURE',
            payload: error.response.data.message
        })
    }
}