import { server } from "../store";
import axios from 'axios';

export const register = (formData)=> async(dispatch)=>{
    try {
        dispatch({
            type: "REGISTER_REQUEST"
        })

        const {data} = await axios.post(`${server}/register`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        });

        dispatch({
            type: "REGISTER_SUCCESS",
            payload: data
        })
        console.log(data);
    } catch (error) {
        dispatch({
            type: 'REGISTER_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const login = (email, password)=> async(dispatch)=>{
    try {
        dispatch({
            type: "LOGIN_REQUEST"
        })

        const {data} = await axios.post(`${server}/login`, {email, password}, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true
        });

        dispatch({
            type: "LOGIN_SUCCESS",
            payload: data
        })
    } catch (error) {
        dispatch({
            type: 'LOGIN_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const getMyProfile = ()=> async(dispatch)=>{
    try {
        dispatch({
            type: "LOAD_USER_REQUEST"
        })

        const {data} = await axios.get(`${server}/me`, {
            withCredentials: true
        });

        dispatch({
            type: "LOAD_USER_SUCCESS",
            payload: data.user
        })
    } catch (error) {
        dispatch({
            type: 'LOAD_USER_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const logout = ()=> async(dispatch)=>{
    try {
        dispatch({
            type: "LOGOUT_REQUEST"
        })

        const {data} = await axios.get(`${server}/logout`, {
            withCredentials: true
        });

        dispatch({
            type: "LOGOUT_SUCCESS",
            payload: data.message
        })
    } catch (error) {
        dispatch({
            type: 'LOGOUT_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const forgetPassword = (email)=> async(dispatch)=>{
    try {
        dispatch({
            type: "FORGET_PASSWORD_REQUEST"
        })

        const {data} = await axios.post(`${server}/forgetPassword`, {email}, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true
        });

        dispatch({
            type: "FORGET_PASSWORD_SUCCESS",
            payload: data.message
        })
        console.log(data);
    } catch (error) {
        dispatch({
            type: 'FORGET_PASSWORD_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const resetPassword = (token, password)=> async(dispatch)=>{
    try {
        dispatch({
            type: "RESET_PASSWORD_REQUEST"
        })

        const {data} = await axios.put(`${server}/resetPassword/${token}`, {password}, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true
        });

        dispatch({
            type: "RESET_PASSWORD_SUCCESS",
            payload: data.message
        })
        console.log(data);
    } catch (error) {
        dispatch({
            type: 'RESET_PASSWORD_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const createSubscription = ()=> async(dispatch)=>{
    try {
        dispatch({
            type: "CREATE_SUBSCRIPTION_REQUEST"
        })

        const {data} = await axios.post(`${server}/subscribe` , {}, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true
        });

        dispatch({
            type: "CREATE_SUBSCRIPTION_SUCCESS",
            payload: data.subscriptionId
        })
    } catch (error) {
        dispatch({
            type: 'CREATE_SUBSCRIPTION_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const cancelSubscription = ()=> async(dispatch)=>{
    try {
        dispatch({
            type: "CANCEL_SUBSCRIPTION_REQUEST"
        })

        const {data} = await axios.delete(`${server}/subscribe/cancel` , {
            withCredentials: true
        });

        dispatch({
            type: "CANCEL_SUBSCRIPTION_SUCCESS",
            payload: data.message
        })
    } catch (error) {
        dispatch({
            type: 'CANCEL_SUBSCRIPTION_FAILURE',
            payload: error.response.data.message
        })
    }
}