import { configureStore } from '@reduxjs/toolkit'
import { subscriptionReducer, userReducer } from './reducers/userReducers';
import { profileReducer } from './reducers/profileReducer';
import { courseReducer } from './reducers/courseReducer';
import { adminReducer } from './reducers/adminReducer';
import { additionalReducer } from './reducers/additionalReducer';
import { reviewReducer } from './reducers/reviewReducer';

const store = configureStore({
  reducer: {
    user: userReducer,
    profile: profileReducer,
    course: courseReducer,
    subscription: subscriptionReducer,
    admin: adminReducer,
    review: reviewReducer,
    other : additionalReducer
  },
})

export default store;

export const server = "https://course-bundler-9kyx.onrender.com/api/v1";