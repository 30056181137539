import React, { useState, useEffect } from 'react'
import {Button, Container, HStack, Heading, Image, Input, Stack, Text, VStack} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import { getAllCourses } from '../../redux/actions/courseAction';
import toast from 'react-hot-toast';
import { addToPlaylist } from '../../redux/actions/profileAction';
import { getMyProfile } from '../../redux/actions/userAction';
import Loader from '../Layout/Loader/Loader';
import MetaData from '../Layout/MetaData/MetaData';

const Course = ({views, title, id, imageSrc, addToPlaylistHandler, creator, description, lectureCount, loading})=>{
    return (
        <VStack className='course' alignItems={["center", "flex-start"]}>
            <Image src={imageSrc}  objectFit={'fill'} height={"130"} width={"220px"} />
            <Heading 
                textAlign={["center", "left"]} 
                maxW="200px" 
                size={'sm'}
                fontFamily={"sans-serif"}
                noOfLines={2}
                children={title}
                height={"40px"}
            />
            <Text children={description} noOfLines={2} />
            <HStack>
                <Text children={"Creator"} fontWeight={'bold'} textTransform={"uppercase"}/>
                <Text children={creator} fontFamily={'body'} textTransform={"uppercase"}/>
            </HStack>

            <Heading textAlign={"center"} size="xs" children={`No of Lectures: ${lectureCount}`} textTransform={"uppercase"} />
            <Heading size="xs" children={`Views: ${views}`} textTransform={"uppercase"} />

            <Stack direction={["column", "row"]} alignItems={"center"}>
                <Link to={`/course/${id}`} >
                    <Button colorScheme='yellow'>Watch Now</Button>
                </Link>
                <Button colorScheme='yellow' variant={"ghost"} onClick={()=> addToPlaylistHandler(id)}>Add To Playlist</Button>
            </Stack>
        </VStack>
    )
}

const Courses = () => {

  const [keyword, setKeyword] = useState("");
  const [category, setCategory] = useState("");
  const categories = [
    "Web Development",
    "Data Science",
    "Android Development",
    "Data Structure and Algorithms",
    "Cloud Computing",
    "Cyber Security",
    "Game Development"
  ]

  const dispatch = useDispatch()
  const {courses, error, loading, message} = useSelector(state => state.course);

  useEffect(() => {
    dispatch(getAllCourses(category, keyword));

    if(error){
        toast.error(error);
        dispatch({
            type: "CLEAR_ERROR"
        })
    }

    if(message){
        toast.success(message);
        dispatch({
            type: "CLEAR_MESSAGE"
        })
    }

  }, [dispatch, category, keyword, error, message]);
  

  const addToPlaylistHandler = async(courseId)=>{
    await dispatch(addToPlaylist(courseId));
    dispatch(getMyProfile());
  }

  return (
        <>
            <MetaData title="Courses" />
            <Container maxW="container.lg" paddingY={'8'}>
                <Heading children="All Courses" m={'8'} />
                <Input 
                    value={keyword} 
                    onChange={e => setKeyword(e.target.value)} 
                    placeholder='Search a course...'
                    type='text'
                    focusBorderColor='yellow.500'
                />

                <HStack overflow={"auto"} paddingY={'8'} css={{"&::-webkit-scrollbar" : {
                    display: "none"
                }}}>
                    {categories.map((item, index)=>(
                    <Button key={index} onClick={()=>setCategory(item)} minW={'60'}>
                            <Text children={item} />
                    </Button> 
                    ))}
                </HStack>

                <HStack mt={"2"} mb={"10"}>
                    <Text children="Selected Category: " fontSize={"xl"} fontWeight={"medium"}/>
                    {category === "" ? (<Text children="All"  fontSize={"xl"}/> ): (<Text children={category}  fontSize={"xl"}/> )}
                </HStack>

                <Stack 
                    direction={["column", "row"]}
                    flexWrap={'wrap'}
                    justifyContent={["flex-start", "space-evenly"]}
                    alignItems={["center", "flex-start"]}
                    spacing={"10"}
                >
                    {
                        courses.length> 0 ? (
                            courses.map((course)=>(
                                <Course
                                    key={course._id}
                                    title={course.title}
                                    description={course.description}
                                    views={course.views}
                                    imageSrc={course.poster.url}
                                    id={course._id}
                                    creator={course.createdBy}
                                    lectureCount={course.numOfVideos}
                                    addToPlaylistHandler={() => addToPlaylistHandler(course._id)}
                                    loading={loading}
                                />
                            ))
                            ) : (
                                <Heading children={loading ? <Loader /> : "Coming Soon!"} opacity={"0.8"} textTransform={"uppercase"} color={"yellow.400"} mb={"8"}/>
                                )
                            }
                </Stack>          
            </Container>
        </>
  
)}

export default Courses