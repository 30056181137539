import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Home from './components/Home/Home';
import Header from './components/Layout/Header/Header';
import Courses from './components/Courses/Courses';
import Footer from './components/Layout/Footer/Footer';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import ForgetPassword from './components/Auth/ForgetPassword';
import ResetPassword from './components/Auth/ResetPassword';
import Contact from './components/Contact/Contact';
import Request from './components/Request/Request';
import About from './components/About/About';
import Subscribe from './components/Payments/Subscribe';
import NotFound from './components/Layout/NotFound/NotFound';
import PaymentSuccess from './components/Payments/PaymentSuccess';
import PaymentFailure from './components/Payments/PaymentFailure';
import CoursePage from './components/CourseDetail/CoursePage';
import Profile from './components/Profile/Profile';
import UpdateProfile from './components/Profile/UpdateProfile';
import ChangePassword from './components/Profile/ChangePassword';
import Dashboard from './components/Admin/Dashboard/Dashboard';
import CreateCourse from './components/Admin/CreateCourse/CreateCourse';
import AdminCourses from './components/Admin/AdminCourses/AdminCourses';
import Users from './components/Admin/Users/Users';
import { useDispatch, useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import { getMyProfile } from './redux/actions/userAction';
import {ProtectedRoute} from 'protected-route-react'
import Loader from './components/Layout/Loader/Loader';
import TermsConditions from './components/TermsConditions/TermsConditions';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import CancellationPolicy from './components/CancellationPolicy/CancellationPolicy';
import ContactMessages from './components/Admin/ContactMessages/ContactMessages';
import Requests from './components/Admin/Requests/Requests';

function App() {

  window.addEventListener("contextmenu" , e=>{
    e.preventDefault();
  })

  const {isAuthenticated, user, message, error, loading} = useSelector(state=>state.user)
  const dispatch = useDispatch();
  useEffect(() => {
    if(error){
      toast.error(error);
      dispatch({
        type: "CLEAR_ERROR"
      })
    }
    if(message){
      toast.success(message);
      dispatch({
        type: "CLEAR_MESSAGE"
      })
    }
  }, [dispatch, message, error]);


  useEffect(() => {
    dispatch(getMyProfile());
  }, [dispatch])
  

  return (
    <Router>
      {
        loading ? (
          <Loader />
        ) : (
          <>
            <Header isAuthenticated={isAuthenticated} user={user} />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/courses" element={<Courses />} />
              <Route path="/login" element={
                <ProtectedRoute isAuthenticated={!isAuthenticated} redirect="/myProfile">
                  <Login />
                </ProtectedRoute>
              } />
              <Route path="/register" element={
                <ProtectedRoute isAuthenticated={!isAuthenticated} redirect="/myProfile">
                  <Register />
                </ProtectedRoute>} />
              <Route path="/forgetPassword" element={
                <ProtectedRoute isAuthenticated={!isAuthenticated} redirect="/myProfile">
                  <ForgetPassword />
                </ProtectedRoute>
              } />
              <Route path="/resetPassword/:token" element={
                <ProtectedRoute isAuthenticated={!isAuthenticated} redirect="/myProfile">
                  <ResetPassword />
                </ProtectedRoute>
              } />
              <Route path="/contact" element={<Contact />} />
              <Route path="/request" element={<Request />} />
              <Route path="/about" element={<About />} />
              <Route path="/termsConditions" element={<TermsConditions />} />
              <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/cancellationPolicy" element={<CancellationPolicy />} />
              <Route path="/subscribe" element={
                <ProtectedRoute isAuthenticated={isAuthenticated} >
                  <Subscribe />
                </ProtectedRoute>
              } />
              <Route path="/paymentSuccess" element={<PaymentSuccess />} />
              <Route path="/paymentFailure" element={<PaymentFailure />} />
              <Route path="/course/:id" element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <CoursePage />
                </ProtectedRoute>
              } />
              <Route path="/myProfile" element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Profile user={user} />
                </ProtectedRoute>
              } />
              <Route path="/updateProfile" element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <UpdateProfile user={user} />
                </ProtectedRoute>
              } />
              <Route path="/changePassword" element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <ChangePassword />
                </ProtectedRoute>
              } />
              <Route path="*" element={<NotFound />} />

              {/* ADMIN ROUTES */}
              <Route path="/admin/dashboard" element={
                <ProtectedRoute isAuthenticated={isAuthenticated} adminRoute={true} isAdmin={user && user.role === "admin"}>
                  <Dashboard />
                </ProtectedRoute>
              } />
              <Route path="/admin/createCourse" element={
                <ProtectedRoute isAuthenticated={isAuthenticated} adminRoute={true} isAdmin={user && user.role === "admin"}>
                  <CreateCourse />
              </ProtectedRoute>
              } />
              <Route path="/admin/courses" element={
                <ProtectedRoute isAuthenticated={isAuthenticated} adminRoute={true} isAdmin={user && user.role === "admin"}>
                  <AdminCourses />
              </ProtectedRoute>
              } />
              <Route path="/admin/users" element={
                <ProtectedRoute isAuthenticated={isAuthenticated} adminRoute={true} isAdmin={user && user.role === "admin"}>
                  <Users />
              </ProtectedRoute>
              } />
              <Route path="/admin/contact" element={
                <ProtectedRoute isAuthenticated={isAuthenticated} adminRoute={true} isAdmin={user && user.role === "admin"}>
                  <ContactMessages />
              </ProtectedRoute>
              } />
              <Route path="/admin/requests" element={
                <ProtectedRoute isAuthenticated={isAuthenticated} adminRoute={true} isAdmin={user && user.role === "admin"}>
                  <Requests />
              </ProtectedRoute>
              } />
            </Routes>
            <Footer />

            <Toaster />
          </>
        )
      }
    </Router>
  );
}

export default App;
