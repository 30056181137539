import { Avatar, Button, Container, HStack, Heading, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text, VStack, useDisclosure } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {RiDeleteBin7Fill} from 'react-icons/ri';
import { fileUploadCss } from '../Auth/Register';
import {updateProfilePicture} from '../../redux/actions/profileAction.js'
import { useDispatch, useSelector } from 'react-redux'
import { removeFromPlaylist } from '../../redux/actions/profileAction';
import { toast } from 'react-hot-toast';
import { cancelSubscription, getMyProfile } from '../../redux/actions/userAction';
import MetaData from '../Layout/MetaData/MetaData';

const Profile = ({user}) => {

    const dispatch = useDispatch();

    const { loading, message, error } = useSelector(state => state.profile);
    const { loading: subscriptionLoading, message: subscriptionMessage, error: subscriptionError } = useSelector(state => state.subscription);

    useEffect(() => {
      if(error){
        toast.error(error);
        dispatch({
            type: "CLEAR_ERROR"
          })
      }
      if(message){
        toast.success(message);
        dispatch({
          type: "CLEAR_MESSAGE"
        })
      }
      if(subscriptionError){
        toast.error(subscriptionError);
        dispatch({
            type: "CLEAR_ERROR"
          })
      }
      if(subscriptionMessage){
        toast.success(subscriptionMessage);
        dispatch({
          type: "CLEAR_MESSAGE"
        })

        dispatch(getMyProfile())
      }
    }, [dispatch, message, error, subscriptionError, subscriptionMessage]);
    
    const removeFromPlaylistHandler = async(id)=>{
        await dispatch(removeFromPlaylist(id));
        dispatch(getMyProfile());
    }

    const changeImageSubmitHandler = async(e, image)=>{
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", image)
        
        await dispatch(updateProfilePicture(formData));
        dispatch(getMyProfile());
    }

    const cancelSubscriptionHandler =()=>{
        dispatch(cancelSubscription());
    }

    const {isOpen, onClose, onOpen} = useDisclosure();

  return (
    <>
        <MetaData title="My Profile" />
        <Container minH={'95vh'} maxW={'container.lg'} py={'8'}>
            <Heading children="Profile" m='8' textTransform={"uppercase"} />
            <Stack 
                justifyContent={'flex-start'} 
                direction={['column', 'row']} 
                alignItems={'center'}
                spacing={['8','16']}
                padding={'8'}
            >
                <VStack>
                    <Avatar boxSize={'48'} src={user.avatar.url} />
                    <Button colorScheme='yellow' variant={'ghost'} onClick={onOpen}>Change Photo</Button>
                </VStack>

                <VStack spacing={'4'} alignItems={["center", "flex-start"]}>
                    <HStack>
                        <Text children="Name:" fontWeight={'bold'} />
                        <Text children={user.name} />
                    </HStack>
                    <HStack>
                        <Text children="Email:" fontWeight={'bold'} />
                        <Text children={user.email} />
                    </HStack>
                    <HStack>
                        <Text children="CreatedAt:" fontWeight={'bold'}/>
                        <Text children={user.createdAt.split("T")[0]} />
                    </HStack>
                    {user.role !== "admin" && (<HStack>
                        <Text children={"Subscription: "} fontWeight={"bold"} />
                        {user.subscription && user.subscription.status === "active" ? 
                            (<Button isLoading={subscriptionLoading} onClick={cancelSubscriptionHandler} colorScheme='yellow' variant={'ghost'}>Cancel Subscription</Button>) : 
                            (<Link to="/subscribe">
                                <Button colorScheme='yellow'> Subscribe</Button>
                            </Link>)
                        }
                    </HStack>)}

                    <Stack direction={['column', 'row']} alignItems={'center'}>
                        <Link to="/updateProfile">
                            <Button>Update Profile</Button>
                        </Link>
                        <Link to="/changePassword">
                            <Button>Change Password</Button>
                        </Link>
                    </Stack>
                </VStack>

            </Stack>

            <Heading size={'md'}>My Playlist</Heading>
            {user.playlist.length > 0 && (
                <Stack direction={["column", "row"]} alignItems={"center"} flexWrap={'wrap'} p={'4'}>
                    {user.playlist.map((element, index)=>(
                        <VStack w="48" m="2" key={element.course}>
                            <Image height={"100px"}  width="150px" objectFit={'fill'} src={element.poster} />
                            <HStack>
                                <Link to={`/course/${element.course}`}>
                                    <Button variant={'ghost'} colorScheme='yellow'>Watch Now</Button>
                                </Link>
                                <Button onClick={() => removeFromPlaylistHandler(element.course)}>
                                    <RiDeleteBin7Fill />
                                </Button>
                            </HStack>
                        </VStack>
                    ))}
                </Stack>
            )}

            <ChangePhotoBox isOpen={isOpen} onClose={onClose} changeImageSubmitHandler={changeImageSubmitHandler} loading={loading} />
        </Container>
    </>
  )
}

export default Profile

function ChangePhotoBox({isOpen, onClose, changeImageSubmitHandler, loading}){

    const [image,setImage] = useState("")
    const [imagePreview,setImagePreview] = useState("")

    const changeImageHandler = (e)=>{
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = ()=>{
            setImagePreview(reader.result)
            setImage(file)
        }
    }

    const modalClose = ()=>{
        onClose();
        setImagePreview("");
        setImage("");
    }

    return (
        <Modal isOpen={isOpen} onClose={modalClose}>
            <ModalOverlay backdropFilter={'blur(10px)'} />
            <ModalContent>
                <ModalHeader>
                    Change Profile Picture
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Container>
                        <form onSubmit={(e) => changeImageSubmitHandler(e, image)}>
                            <VStack spacing={'8'}>
                                {imagePreview && <Avatar src={imagePreview} boxSize={'48'} />}
                                <Input 
                                    required
                                    type="file" 
                                    css={{"&::file-selector-button": fileUploadCss}} 
                                    onChange={changeImageHandler}
                                />
                                <Button w="full" colorScheme='yellow' type='submit' isLoading={loading} >Change Profile Picture</Button>
                            </VStack>
                        </form>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button mr="3" onClick={modalClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}