import { server } from "../store";
import axios from 'axios';

export const updateProfile = (name, email)=> async(dispatch)=>{
    try {
        dispatch({
            type: "UPDATE_PROFILE_REQUEST"
        })

        const {data} = await axios.put(`${server}/updateProfile`, {name, email}, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true
        });

        dispatch({
            type: "UPDATE_PROFILE_SUCCESS",
            payload: data.message
        })
        console.log(data);
    } catch (error) {
        dispatch({
            type: 'UPDATE_PROFILE_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const changePassword = (oldPassword, newPassword)=> async(dispatch)=>{
    try {
        dispatch({
            type: "CHANGE_PASSWORD_REQUEST"
        })

        const {data} = await axios.put(`${server}/updatePassword`, {oldPassword, newPassword}, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true
        });

        dispatch({
            type: "CHANGE_PASSWORD_SUCCESS",
            payload: data.message
        })
        console.log(data);
    } catch (error) {
        dispatch({
            type: 'CHANGE_PASSWORD_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const updateProfilePicture = (formData)=> async(dispatch)=>{
    try {
        dispatch({
            type: "UPDATE_PROFILE_PICTURE_REQUEST"
        })

        const {data} = await axios.put(`${server}/updateProfilePicture`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        });

        dispatch({
            type: "UPDATE_PROFILE_PICTURE_SUCCESS",
            payload: data.message
        })
        console.log(data);
    } catch (error) {
        dispatch({
            type: 'UPDATE_PROFILE_PICTURE_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const addToPlaylist = (id)=> async(dispatch)=>{
    try {
        dispatch({
            type: "ADD_TO_PLAYLIST_REQUEST"
        })

        const {data} = await axios.post(`${server}/addToPlaylist`, {id}, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true
        });


        dispatch({
            type: "ADD_TO_PLAYLIST_SUCCESS",
            payload: data.message
        })
    } catch (error) {
        dispatch({
            type: 'ADD_TO_PLAYLIST_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const removeFromPlaylist = (id)=> async(dispatch)=>{
    try {
        dispatch({
            type: "REMOVE_FROM_PLAYLIST_REQUEST"
        })

        const {data} = await axios.delete(`${server}/removeFromPlaylist?id=${id}`, 
         {
            withCredentials: true
         }
        );

        dispatch({
            type: "REMOVE_FROM_PLAYLIST_SUCCESS",
            payload: data.message
        })
    } catch (error) {
        dispatch({
            type: 'REMOVE_FROM_PLAYLIST_FAILURE',
            payload: error.response.data.message
        })
    }
}