import React from 'react'
import { HStack, Heading, Container, Text } from '@chakra-ui/react'
import {RiSecurePaymentFill} from 'react-icons/ri'
import MetaData from '../Layout/MetaData/MetaData'

const CancellationPolicy = () => {
  return (
    <>
    <MetaData title={"Cancellation Policy"} />
    <Container minH={'75vh'} maxW={'container.lg'} py={'16'}>
        <Heading children={"Refund & Cancellation Policy"} mb={"12"} />
        <Text fontSize={'xl'} my="4" children={"Refund only applicable, if cancellation of the subscription is done within 7 days."} />
        <HStack my={'4'} py={"4"}>
            <RiSecurePaymentFill />
            <Heading children={'Payment is secured by RazorPay'} size={'xs'} fontFamily={'sans-serif'} textTransform={'uppercase'}/>
        </HStack>
    </Container>
    </>
    
  )
}

export default CancellationPolicy