import { server } from "../store";
import axios from 'axios';

export const addLectureReview = (courseId, reviewData)=> async(dispatch)=>{
    try {
        dispatch({
            type: "ADD_LECTURE_REVIEW_REQUEST"
        })

        const {data} = await axios.put(`${server}/review?courseId=${courseId}`, reviewData, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true
        });

        dispatch({
            type: "ADD_LECTURE_REVIEW_SUCCESS",
            payload: data.message
        })
    } catch (error) {
        dispatch({
            type: 'ADD_LECTURE_REVIEW_FAILURE',
            payload: error.response.data.message
        })
    }
}

export const deleteLectureReview = (courseId, lectureId, reviewId)=> async(dispatch)=>{
    try {
        dispatch({
            type: "DELETE_LECTURE_REVIEW_REQUEST"
        })

        const {data} = await axios.delete(`${server}/review?courseId=${courseId}&lectureId=${lectureId}&reviewId=${reviewId}`, 
            {withCredentials: true}
        );

        dispatch({
            type: "DELETE_LECTURE_REVIEW_SUCCESS",
            payload: data.message
        })
    } catch (error) {
        dispatch({
            type: 'DELETE_LECTURE_REVIEW_FAILURE',
            payload: error.response.data.message
        })
    }
}
