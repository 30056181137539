import { Box, Button, Container, HStack, Heading, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import {RiCheckboxCircleFill} from 'react-icons/ri'
import { Link, useSearchParams } from 'react-router-dom';
import MetaData from '../Layout/MetaData/MetaData';

const PaymentSuccess = () => {
    const referenceId = useSearchParams()[0].get("reference")
  return (
    <>
        <MetaData title="Payment Success" />
        <Container h={'110vh'} p={'12'}>
            <Heading my="8" textAlign="center" >You have Pro Pack</Heading>
            <VStack boxShadow={'lg'} pb={'10'} alignItems={"center"} borderRadius={'lg'}>
                <Box w="full" bg="yellow.400" p="4" css={{borderRadius: "8px 8px 0 0 "}}>
                    <Text color="black">Payment Success</Text>
                </Box>
                <Box p={'4'}>
                    <VStack textAlign={"center"} px={'8'} mt={'4'} spacing={'8'}>
                        <Text>Congratulations! You are a Pro Member. Now, You have access to Premium Content.</Text>
                        <Heading size={'4xl'}>
                            <RiCheckboxCircleFill color='green' />
                        </Heading>
                    </VStack>
                </Box>

                <Link to="/myProfile">
                    <Button variant={'ghost'}>Go To Profile</Button>
                </Link>
                <HStack>
                    <Heading size={'xs'} children={"Reference: "} />
                    <Text size={'xs'} children={referenceId} />
                </HStack>
                
            </VStack>

        </Container>
    </>
  )
}

export default PaymentSuccess