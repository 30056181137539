import React, { useEffect, useState } from 'react'
import {Box, Button, Container, FormLabel, Grid, Heading, Image, Input, Select, VStack } from '@chakra-ui/react'
import cursor from '../../../assets/images/cursor.png'
import Sidebar from '../Sidebar'
import { fileUploadCss } from '../../Auth/Register'
import { createCourse } from '../../../redux/actions/adminAction'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-hot-toast';
import MetaData from '../../Layout/MetaData/MetaData';

const CreateCourse = () => {

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");

  const dispatch = useDispatch();
  const categories = [
    "Web Development",
    "Data Science",
    "Android Development",
    "Data Structure and Algorithms",
    "Cloud Computing",
    "Cyber Security",
    "Game Development"
  ]

  const changeImageHandler = (e)=>{
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = ()=>{
        setImagePreview(reader.result)
        setImage(file)
    }
  }

  const submitHandler = (e)=>{
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title)
    formData.append("description", description)
    formData.append("category", category)
    formData.append("file", image)
        
    dispatch(createCourse(formData));
  }

  const {loading, error, message} = useSelector(state=>state.admin)

  useEffect(() => {
    if(error){
      toast.error(error);
      dispatch({
        type: "CLEAR_ERROR",
      })
    }

    if(message){
      toast.success(message);
      dispatch({
        type: "CLEAR_MESSAGE",
      })
      setTitle("");
      setDescription("");
      setCategory("");
      setImagePreview("");
      setImage("");
    }

  }, [error, dispatch, message]);

  return (
    <>
      <MetaData title="Create Course" />
      <Grid templateColumns={["1fr", "5fr 1fr"]} minH={"100vh"} css={{cursor:`url(${cursor}), default`}}>
          <Container py={'10'}>
            <Heading children="Create Course" textTransform={'uppercase'} my={'10'} textAlign={["center", "left"]} />
            <VStack>
              <form style={{width:'100%'}} onSubmit={submitHandler}>
                <Box marginY={"4"}>
                        <FormLabel htmlFor='title' children="Title" />
                        <Input 
                            id='title' 
                            required
                            value={title} 
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Enter Title of a course"
                            type='text'
                            focusBorderColor='purple.300' 
                        />
                    </Box>

                    <Box marginY={"4"}>
                        <FormLabel htmlFor='desc' children="Description" />
                        <Input 
                            id='desc' 
                            required
                            value={description} 
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Enter Description"
                            type='text'
                            focusBorderColor='purple.300' 
                        />
                    </Box>

                    <Box marginY={"4"}>
                        <FormLabel htmlFor='creatorName' children="Choose Category" />
                        <Select focusBorderColor='purple.300' required value={category} onChange={(e) => setCategory(e.target.value)}>
                          <option value="" >Select Category</option>
                          {categories.map(item=>(
                            <option value={item} key={item}>{item}</option>
                          ))}
                        </Select>
                    </Box>

                    <Box marginY={"4"}>
                      <FormLabel htmlFor='choosePoster' children="Choose Image Poster" />
                      <Input 
                          accept='image/*'
                          required 
                          id='choosePoster'
                          type='file' 
                          focusBorderColor='purple.300' 
                          css={{
                            "&::file-selector-button":{
                              ...fileUploadCss,
                              color: "purple"
                            }
                          }} 
                          onChange={changeImageHandler}
                      />
                  </Box>

                  {imagePreview && (
                    <Box display={'flex'} justifyContent={'center'}>
                      <Image src={imagePreview} boxSize={"64"}  objectFit={"contain"}/>
                    </Box>
                  )}
                  

                    <Button isLoading={loading} loadingText="Creating..." my="4" colorScheme='purple' type='submit' w={'full'}>Create Course</Button>
              </form>
            </VStack>
          </Container>
          <Sidebar />
      </Grid>
    </>
  )
}

export default CreateCourse