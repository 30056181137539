import { Box, Button, Container, FormLabel, Heading, Input, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { forgetPassword } from '../../redux/actions/userAction';
import MetaData from '../Layout/MetaData/MetaData';

const ForgetPassword = () => {
    const [email, setEmail] = useState("");
    const dispatch = useDispatch();
    const {loading} = useSelector(state=>state.user)

    const submitHandler = (e)=>{
        e.preventDefault();
        dispatch(forgetPassword(email));
    }
    
  return (
    <>
    <MetaData title={"Forget Password"} />
    <Container py={'16'} h={'90vh'}>
        <VStack h={"full"} justifyContent={"center"} spacing={'16'}>
            <Heading children={"Forget Password"} textTransform={'uppercase'} />
            <form style={{width:'100%'}} onSubmit={submitHandler}>
                <Box marginY={"4"}>
                    <FormLabel htmlFor='email' children="Email Address" />
                    <Input 
                        required 
                        id='email' 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder='abc@example.com'
                        type='email'
                        focusBorderColor='yellow.500' 
                    />
                </Box>
                <Button isLoading={loading} type='submit' w={'full'} colorScheme='yellow'>Send Reset Link</Button>
            </form>
            
        </VStack>
    </Container>
    </>
  )
}

export default ForgetPassword