import React from 'react'
import {Button, VStack } from '@chakra-ui/react'
import {Link, useLocation} from 'react-router-dom';
import {RiDashboardFill, RiEyeFill, RiUser3Fill, RiAddCircleFill, RiContactsBookFill, RiMessageFill} from 'react-icons/ri';

const Sidebar = () => {
    const location = useLocation();
  return (
    <VStack spacing={'12'} p="10" boxShadow={"-2px 0 10px rgba(107, 70, 193, 0.5)"} >
        <LinkButton Icon={RiDashboardFill} url={"dashboard"} text="Dashboard" active={location.pathname === "/admin/dashboard"} />
        <LinkButton Icon={RiAddCircleFill} url={"createCourse"} text="Create Course" active={location.pathname === "/admin/createCourse"} />
        <LinkButton Icon={RiEyeFill} url={"courses"} text="Courses" active={location.pathname === "/admin/courses"} />
        <LinkButton Icon={RiUser3Fill} url={"users"} text="Users" active={location.pathname === "/admin/users"} />
        <LinkButton Icon={RiContactsBookFill} url={"contact"} text="Contact Messages" active={location.pathname === "/admin/contact"} />
        <LinkButton Icon={RiMessageFill} url={"requests"} text="Requests" active={location.pathname === "/admin/requests"} />
    </VStack>
  )
}

export default Sidebar

function LinkButton({url, Icon, text, active}){
    return (
        <Link to={`/admin/${url}`}>
            <Button colorScheme={active?"purple":""} fontSize={'larger'} variant={"ghost"}>
                <Icon style={{margin: '4px'}} />
                {text}
            </Button>
        </Link>
    )
}