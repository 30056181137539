import { Box, Button, Container, FormLabel, Heading, Input, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import { login } from '../../redux/actions/userAction'
import MetaData from '../Layout/MetaData/MetaData'


const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();

    const submitHandler = (e)=>{
        e.preventDefault();
        dispatch(login(email, password));
    }

  return (
    <>
    <MetaData title={"Login"} />
    <Container h={"95vh"}>
        <VStack h={"full"} justifyContent={"center"} spacing={'16'}>
            <Heading children={"Welcome to Course Compass"} />
            <form style={{width:'100%'}} onSubmit={submitHandler}>
                <Box marginY={"4"}>
                    <FormLabel htmlFor='email' children="Email Address" />
                    <Input 
                        required 
                        id='email' 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder='abc@example.com'
                        type='email'
                        focusBorderColor='yellow.500' 
                    />
                </Box>

                <Box marginY={"4"}>
                    <FormLabel htmlFor='password' children="Password" />
                    <Input 
                        required 
                        id='password' 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder='*********'
                        type='password' 
                        focusBorderColor='yellow.500' 
                    />
                </Box>

                <Box>
                    <Link to='/forgetPassword' >
                        <Button fontSize={'sm'} variant={'link'}>Forget Password?</Button>
                    </Link>
                </Box>
                <Button my="4" colorScheme='yellow' type='submit' w={'full'}>Login</Button>
                <Box my={'4'}>
                    Not a Registered User? <Link to='/register'>
                        <Button variant={'link'} colorScheme='yellow'>Create Account</Button>
                    </Link>
                </Box>
            </form>
        </VStack>
    </Container>
    </>
  )
}

export default Login