import React from 'react'
import { Container, VStack, Heading, Text, OrderedList, ListItem, UnorderedList } from '@chakra-ui/react';
import MetaData from '../Layout/MetaData/MetaData';

const PrivacyPolicy = () => {
  return (
    <>
      <MetaData title="Privacy Policy" />
      <Container minH={'95vh'} maxW={'container.lg'} py={'12'}>
        <Heading children={"Privacy Policy"} mb={"12"} />

        <VStack spacing={"10"} fontWeight={"medium"}>
            <Text
                align={"justify"}
                children="This Privacy Policy (the “Policy”) governs the manner in which the Platform collects, uses, maintains and discloses information of its users. The Policy also describes the practices that We apply to such user information, user’s privacy rights and choices regarding their information. To clarify, this Policy applies to all users of the Platform (referred to as “Learners”, “You”, “Your”)."
            />

            <Text align={"justify"}>
              By accessing and using the Platform, providing Your Personal Information, or by otherwise signalling Your agreement when the option is presented to You, You consent to the collection, use, and disclosure of information described in this Policy and Terms of Use and we disclaim all the liabilities arising therefrom. If You do not agree with any provisions of this Policy and/or the Terms of Use, You should not access or use the Platform or engage in communications with us and are required to leave the Platform immediately. If any information You have provided or uploaded on the Platform violates the terms of this Policy or Terms of Use, we may be required to delete such information upon informing You of the same and revoke Your access if required without incurring any liability to You. Capitalized terms used but not defined in this Privacy Policy can be found in our Terms of Use. Please read this Policy carefully prior to accessing our Platform and using any of the services or products offered therein. If you have any questions, please contact us at the contact information provided below.
            </Text>

            <VStack alignItems={"flex-start"}>
                <OrderedList spacing={"4"}>
                    <ListItem>PERSONAL INFORMATION: </ListItem>
                    <Text fontWeight={"normal"} align={"justify"}
                    children="“Personal Information” shall mean the information which identifies a Learner i.e., first and last name, identification number, email address, age, gender, location, photograph and/or phone number provided at the time of registration or any time thereafter on the Platform."
                    />
                    <Text fontWeight={"normal"} align={"justify"}
                    children="“Sensitive Personal Information” shall include (i) passwords and financial data (except the truncated last four digits of credit/debit card), (ii) health data, (iii) official identifier (such as biometric data, aadhar number, social security number, driver’s license, passport, etc.,), (iv) information about sexual life, sexual identifier, race, ethnicity, political or religious belief or affiliation, (v) account details and passwords, or (vi) other data/information categorized as ‘sensitive personal data’ or ‘special categories of data’ under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011, General Data Protection Regulation (GDPR) and / or the California Consumer Privacy Act (CCPA) (“Data Protection Laws”) and in context of this Policy or other equivalent / similar legislations."
                    />
                    <Text fontWeight={"normal"} align={"justify"}
                    children="Usage of the term ‘Personal Information’ shall include ‘Sensitive Personal Information’ as may be applicable to the context of its usage."
                    />

                    <ListItem>INFORMATION WE COLLECT: </ListItem>
                    <Text fontWeight={"normal"} align={"justify"}
                        children="We may collect both personal and non-personal identifiable information from You in a variety of ways, including, but not limited to, when You visit our Platform, register on the Platform, and in connection with other activities, services, features or resources we make available on our Platform. However, please note that-"
                    />
                    
                    <UnorderedList >
                        <ListItem fontWeight={"normal"}>We do not ask You for Personal Information unless we truly need it; like when You are registering for any Content on the Platform.</ListItem>
                        <ListItem fontWeight={"normal"}>We do not share Your Personal Information with anyone except to comply with the applicable laws, develop our products and services, or protect our rights.</ListItem>
                        <ListItem fontWeight={"normal"}>We do not store Personal Information on our servers unless required for the on-going operation of our Platform.</ListItem>
                    </UnorderedList>
                </OrderedList>
                
            </VStack>

            <VStack alignItems={"flex-start"} spacing={"8"}>
                
                
            </VStack>
        </VStack>
      </Container>
    </>
  )
}

export default PrivacyPolicy