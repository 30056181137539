import { Box, Button, Container, Heading, Text, VStack } from '@chakra-ui/react'
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux';
import { server } from '../../redux/store';
import { createSubscription } from '../../redux/actions/userAction';
import {toast} from 'react-hot-toast'
import logo from '../../assets/images/logo.png';
import MetaData from '../Layout/MetaData/MetaData';

const Subscribe = () => {

    const dispatch = useDispatch();
    const [key, setKey]= useState("");

    const {loading, error, subscriptionId} = useSelector(state => state.subscription);
    const {error: courseError} = useSelector(state => state.course);
    const {user} = useSelector(state => state.user);

    const subscribeHandler = async()=>{
        const {data} = await axios.get(`${server}/razorpaykey`)
        setKey(data.key);
        dispatch(createSubscription());
    }

    useEffect(() => {
      if(error){
        toast.error(error);
        dispatch({
            type: "CLEAR_ERROR"
        })
      }

      if(courseError){
        toast.error(courseError);
        dispatch({
            type: "CLEAR_ERROR"
        })
      }

      if(subscriptionId){
        const openPopup = ()=>{
            const options = {
                key,
                name: "Course Compass",
                description: "Get access to all Premium Content", 
                image:logo,
                subscription_id: subscriptionId,
                callback_url: `${server}/paymentVerification`,
                prefill: { 
                    name: user.name,
                    email: user.email
                },
                notes: {
                    address: "Course Compass"
                },
                theme: {
                    color :"#FFC800"
                }
            }

            const razor = new window.Razorpay(options)
            razor.open();
        }

        openPopup();
      }
    
    }, [error, dispatch, key, subscriptionId, user.name, user.email, courseError])
    

  return (
    <>
        <MetaData title="Subscribe" />
        <Container h={["120vh", '100vh']} width={"100%"}>
            <Text children={"Welcome to Course Compass"} my="14" textAlign={'center'} fontSize={"4xl"} fontWeight={"medium"}/>
            <VStack boxShadow={'lg'} alignItems={'stretch'} borderRadius={'lg'} spacing={'0'} >
                <Box bg="yellow.400" p="4" css={{borderRadius:"8px 8px 0 0"}}>
                    <Text children={`Pro Pack - ₹299.00`} color={'black'} />
                </Box>
                <Box p={'4'}>
                    <VStack textAlign={'center'} px={'8'} mt={'4'} spacing={'8'}>
                        <Text children={"Join Pro Pack and Get Access to All Content."} />
                        <Heading size={'md'} children={"₹299 Only"} />
                    </VStack>

                    <Button my={"8"} w="full" colorScheme='yellow' onClick={subscribeHandler} isLoading={loading}>
                        Buy Now
                    </Button>
                </Box>

                <Box bg="blackAlpha.600" p="4" css={{borderRadius: "0 0 8px 8px"}}>
                    <Heading 
                        size={'sm'} 
                        children={"100% refund at cancellation"} 
                        color={"white"} 
                        textTransform={"uppercase"} 
                    />
                    <Text fontSize={'xs'} color={'white'} children={"* Terms and Conditions Apply"} />
                </Box>
            </VStack>
        </Container>
    </>
  )
}

export default Subscribe