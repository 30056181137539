import { createReducer } from "@reduxjs/toolkit";

export const userReducer = createReducer({}, {
    REGISTER_REQUEST: (state)=>{
        state.loading = true;
    },
    REGISTER_SUCCESS: (state, action)=>{
        state.loading = false;
        state.isAuthenticated = true;
        state.user = action.payload.user;
        state.message = action.payload.message;
    },
    REGISTER_FAILURE: (state, action)=>{
        state.loading = false;
        state.isAuthenticated = false;
        state.user = null;
        state.error = action.payload;
    },
    LOGIN_REQUEST: (state)=>{
        state.loading = true;
    },
    LOGIN_SUCCESS: (state, action)=>{
        state.loading = false;
        state.isAuthenticated = true;
        state.user = action.payload.user;
        state.message = action.payload.message;
    },
    LOGIN_FAILURE: (state, action)=>{
        state.loading = false;
        state.isAuthenticated = false;
        state.user = null;
        state.error = action.payload;
    },
    LOAD_USER_REQUEST: (state)=>{
        state.loading = true;
    },
    LOAD_USER_SUCCESS: (state, action)=>{
        state.loading = false;
        state.isAuthenticated = true;
        state.user = action.payload;
    },
    LOAD_USER_FAILURE: (state, action)=>{
        state.loading = false;
        state.isAuthenticated = false;
        state.user = null;
        state.error = action.payload;
    },
    LOGOUT_REQUEST: (state)=>{
        state.loading = true;
    },
    LOGOUT_SUCCESS: (state, action)=>{
        state.loading = false;
        state.isAuthenticated = false;
        state.user = null;
        state.message = action.payload;
    },
    LOGOUT_FAILURE: (state, action)=>{
        state.loading = false;
        state.isAuthenticated = true;
        state.error = action.payload;
    },
    FORGET_PASSWORD_REQUEST: (state)=>{
        state.loading = true;
    },
    FORGET_PASSWORD_SUCCESS: (state, action)=>{
        state.loading = false;
        state.message = action.payload;
    },
    FORGET_PASSWORD_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    RESET_PASSWORD_REQUEST: (state)=>{
        state.loading = true;
    },
    RESET_PASSWORD_SUCCESS: (state, action)=>{
        state.loading = false;
        state.message = action.payload;
    },
    RESET_PASSWORD_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    REMOVE_FROM_PLAYLIST_REQUEST: (state)=>{
        state.loading = true;
    },
    REMOVE_FROM_PLAYLIST_SUCCESS: (state, action)=>{
        state.loading = false;
        state.message = action.payload;
    },
    REMOVE_FROM_PLAYLIST_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    CLEAR_ERROR: (state)=>{
        state.error = null;
    },
    CLEAR_MESSAGE: (state)=>{
        state.message = null;
    }
})

export const subscriptionReducer = createReducer({},{
    CREATE_SUBSCRIPTION_REQUEST: (state)=>{
        state.loading = true;
    },
    CREATE_SUBSCRIPTION_SUCCESS: (state, action)=>{
        state.loading = false;
        state.subscriptionId = action.payload;
    },
    CREATE_SUBSCRIPTION_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    CANCEL_SUBSCRIPTION_REQUEST: (state)=>{
        state.loading = true;
    },
    CANCEL_SUBSCRIPTION_SUCCESS: (state, action)=>{
        state.loading = false;
        state.message = action.payload;
    },
    CANCEL_SUBSCRIPTION_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    CLEAR_ERROR: (state)=>{
        state.error = null;
    },
    CLEAR_MESSAGE: (state)=>{
        state.message = null;
    }
});