import {Box, Button, Container, FormLabel, Heading, Input, VStack } from '@chakra-ui/react'
import {  useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {updateProfile} from '../../redux/actions/profileAction.js'
import { getMyProfile } from '../../redux/actions/userAction.js'
import { useNavigate } from 'react-router-dom'
import MetaData from '../Layout/MetaData/MetaData';

const UpdateProfile = ({user}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [name, setName] = useState(user.name);
    const [email, setEmail] = useState(user.email);

    const updateProfileHandler = async(e)=>{
        e.preventDefault();
        
        await dispatch(updateProfile(name, email));
        dispatch(getMyProfile());
        navigate("/myProfile")
    }

    const { loading} = useSelector(state => state.profile);

  return (
    <>
        <MetaData title="Update Profile" />
        <Container h={'90vh'}>
            <VStack h={'full'} justifyContent={'center'} spacing={'16'}>
                <Heading children={'Update Profile'} textTransform={'uppercase'} textAlign={["center", "left"]} />
                <form style={{width:'100%'}} onSubmit={updateProfileHandler}>

                    <Box marginY={"4"}>
                        <FormLabel htmlFor='name' children="Name" />
                        <Input 
                            id='name' 
                            value={name} 
                            onChange={(e) => setName(e.target.value)}
                            placeholder="abc"
                            type='text'
                            focusBorderColor='yellow.500' 
                        />
                    </Box>

                    <Box marginY={"4"}>
                        <FormLabel htmlFor='email' children="Email" />
                        <Input 
                            id='email' 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="abc@gmail.com"
                            type='email'
                            focusBorderColor='yellow.500' 
                        />
                    </Box>

                    <Button my="4" isLoading={loading} loadingText="Updating" colorScheme='yellow' type='submit' w={'full'}>Update</Button>
                    
                </form>
            </VStack>
        </Container>
    </>
  )
}

export default UpdateProfile