import { createReducer } from "@reduxjs/toolkit";

export const additionalReducer = createReducer({}, {
    CONTACT_REQUEST: (state)=>{
        state.loading = true;
    },
    CONTACT_SUCCESS: (state, action)=>{
        state.loading = false;
        state.message = action.payload;
    },
    CONTACT_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    COURSE_REQ_REQUEST: (state)=>{
        state.loading = true;
    },
    COURSE_REQ_SUCCESS: (state, action)=>{
        state.loading = false;
        state.message = action.payload;
    },
    COURSE_REQ_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    GET_CONTACTS_REQUEST: (state)=>{
        state.loading = true;
    },
    GET_CONTACTS_SUCCESS: (state, action)=>{
        state.loading = false;
        state.contacts = action.payload;
    },
    GET_CONTACTS_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    GET_COURSE_REQ_REQUEST: (state)=>{
        state.loading = true;
    },
    GET_COURSE_REQ_SUCCESS: (state, action)=>{
        state.loading = false;
        state.requests = action.payload;
    },
    GET_COURSE_REQ_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    CLEAR_ERROR: (state)=>{
        state.error = null;
    },
    CLEAR_MESSAGE: (state)=>{
        state.message = null;
    }
})