import { createReducer } from "@reduxjs/toolkit";

export const reviewReducer = createReducer({}, {
    GET_LECTURE_REVIEWS_REQUEST: (state)=>{
        state.loading = true;
    },
    GET_LECTURE_REVIEWS_SUCCESS: (state, action)=>{
        state.loading = false;
        state.reviews = action.payload;
    },
    GET_LECTURE_REVIEWS_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    ADD_LECTURE_REVIEW_REQUEST: (state)=>{
        state.loading = true;
    },
    ADD_LECTURE_REVIEW_SUCCESS: (state, action)=>{
        state.loading = false;
        state.message = action.payload;
    },
    ADD_LECTURE_REVIEW_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    DELETE_LECTURE_REVIEW_REQUEST: (state)=>{
        state.loading = true;
    },
    DELETE_LECTURE_REVIEW_SUCCESS: (state, action)=>{
        state.loading = false;
        state.message = action.payload;
    },
    DELETE_LECTURE_REVIEW_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    CLEAR_ERROR: (state)=>{
        state.error = null;
    },
    CLEAR_MESSAGE: (state)=>{
        state.message = null;
    }
})