import { createReducer } from "@reduxjs/toolkit";

export const adminReducer = createReducer({}, {
    GET_DASHBOARD_STATS_REQUEST: (state)=>{
        state.loading = true;
    },
    GET_DASHBOARD_STATS_SUCCESS: (state, action)=>{
        state.loading = false;
        state.stats = action.payload.stats;
        state.usersCount = action.payload.usersCount;
        state.viewsCount = action.payload.viewsCount;
        state.subscriptionsCount = action.payload.subscriptionsCount;
        state.usersPercentage = action.payload.usersPercentage;
        state.viewsPercentage = action.payload.viewsPercentage;
        state.subscriptionsPercentage = action.payload.subscriptionsPercentage;
        state.usersProfit = action.payload.usersProfit;
        state.viewsProfit = action.payload.viewsProfit;
        state.subscriptionsProfit = action.payload.subscriptionsProfit;
    },
    GET_DASHBOARD_STATS_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    CREATE_COURSE_REQUEST: (state)=>{
        state.loading = true;
    },
    CREATE_COURSE_SUCCESS: (state, action)=>{
        state.loading = false;
        state.message = action.payload;
    },
    CREATE_COURSE_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    DELETE_COURSE_REQUEST: (state)=>{
        state.loading = true;
    },
    DELETE_COURSE_SUCCESS: (state, action)=>{
        state.loading = false;
        state.message = action.payload;
    },
    DELETE_COURSE_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    ADD_COURSE_LECTURES_REQUEST: (state)=>{
        state.loading = true;
    },
    ADD_COURSE_LECTURES_SUCCESS: (state, action)=>{
        state.loading = false;
        state.message = action.payload;
    },
    ADD_COURSE_LECTURES_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    DELETE_COURSE_LECTURES_REQUEST: (state)=>{
        state.loading = true;
    },
    DELETE_COURSE_LECTURES_SUCCESS: (state, action)=>{
        state.loading = false;
        state.message = action.payload;
    },
    DELETE_COURSE_LECTURES_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    ALL_USERS_REQUEST: (state)=>{
        state.loading = true;
    },
    ALL_USERS_SUCCESS: (state, action)=>{
        state.loading = false;
        state.users = action.payload;
    },
    ALL_USERS_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    UPDATE_USER_ROLE_REQUEST: (state)=>{
        state.loading = true;
    },
    UPDATE_USER_ROLE_SUCCESS: (state, action)=>{
        state.loading = false;
        state.message = action.payload;
    },
    UPDATE_USER_ROLE_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    DELETE_USER_REQUEST: (state)=>{
        state.loading = true;
    },
    DELETE_USER_SUCCESS: (state, action)=>{
        state.loading = false;
        state.message = action.payload;
    },
    DELETE_USER_FAILURE: (state, action)=>{
        state.loading = false;
        state.error = action.payload;
    },
    CLEAR_ERROR: (state)=>{
        state.error = null;
    },
    CLEAR_MESSAGE: (state)=>{
        state.message = null;
    }
})